<style lang="less" scoped>
.header {
  height: 56px;
  background-color: rgb(240, 242, 244);
  padding: 0px 24px 0px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content {
  padding: 0px 32px 20px 32px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.filter {
  padding-top: 17px;
  display: flex;
  flex-direction: row;
}

.filter-select {
  width: 140px;
}

.search-input {
  width: 100%;
}

.doc-table {
  padding-top: 17px;
  // height: 245px;
}

.record-item {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  color: rgb(102, 102, 102);
}

.record-item-info {
  flex: 1;
}

.requester {
  margin-top: 2px;
  color: rgb(170, 170, 170);
}

.recovery-btn {
  color: rgb(102, 102, 102);
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  height: 18px;
  border-bottom: rgb(102, 102, 102) solid 1px;
}
</style>

<template>
  <a-modal
    v-model:visible="isShow"
    :bodyStyle="{ padding: 0 }"
    :closable="false"
    :destroyOnClose="true"
    :footer="null"
    :keyboard="false"
    :maskClosable="false"
    width="520px"
  >
    <a-spin :spinning="recovering">
      <div>
        <div class="header">
          <h2>삭제된 문서</h2>
          <span class="modal-close" role="button" @click="onClose">닫기</span>
        </div>
        <div class="content">
          <div class="filter">
            <a-select
              v-model:value="filter"
              :options="filterElements"
              class="filter-select"
              @change="filterChange"
            />
            <a-input-search
              v-model:value="keyword"
              :loading="loadingDocuments"
              class="search-input"
              placeholder="100자 이내로 검색해 주세요."
              @search="onSearch"
            />
          </div>
          <a-table
            :bordered="true"
            :dataSource="data.list"
            :loading="loadingDocuments"
            :pagination="pagination"
            :scroll="{ y: 280 }"
            :showHeader="false"
            class="doc-table"
            rowKey="documentId"
            @change="pageChange"
          >
            <a-table-column key="col">
              <template #default="{ record }">
                <div class="record-item">
                  <div class="record-item-info">
                    <p>{{ record.name }}</p>
                    <p class="requester">{{ record.requester.name }}</p>
                  </div>
                  <div>
                    <a-button
                      v-checkPermission="{action: 'DELETE_DOCUMENT', organizationId: record.organizationId, teamId: record.teamId, userId: record.userId}"
                      class="recovery-btn"
                      size="small"
                      type="text"
                      @click="completelyDelete(record)"
                    >
                      완전 삭제
                    </a-button>
                    <a-divider v-checkPermission="{action: 'DELETE_DOCUMENT'}" type="vertical"/>
                    <a-button
                      v-checkPermission="{action: 'DELETE_DOCUMENT', organizationId: record.organizationId, teamId: record.teamId, userId: record.userId}"
                      class="recovery-btn"
                      size="small"
                      type="text"
                      @click="restoreConfirm(record)"
                    >
                      문서 복구
                    </a-button>
                  </div>
                </div>
              </template>
            </a-table-column>
          </a-table>
        </div>
      </div>
    </a-spin>
  </a-modal>
  <UcsConfirm :visible="completelyDeleteVis" cancelText="취소" confirmText="삭제" @onClickCancel="completelyDeleteVis=false" @onClickConfirm="doCompletelyDelete">
    <template #cusTitle>
      <h3>문서 삭제 알림</h3>
    </template>
    <template #cusContent>
      문서를 완전히 삭제하시겠습니까?<br/>
      삭제한 문서는 복구할 수 없습니다.
    </template>
  </UcsConfirm>
  <UcsConfirm :visible="restoreConfirmVis" cancelText="취소" confirmText="복구" @onClickCancel="restoreConfirmVis=false" @onClickConfirm="restore">
    <template #cusTitle>
      <h3>문서 복구</h3>
    </template>
    <template #cusContent>
      선택한 문서를 복구하시겠습니까?
    </template>
  </UcsConfirm>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, toRefs, watchEffect } from 'vue'
import { getDeleteDocuments as getDeleteDocumentsApi, restoreDocument as restoreDocumentApi } from 'api/organization'
import { completelyDelete as completelyDeleteApi } from 'api/documents'
import { Modal } from 'ant-design-vue'
import { useStore } from 'vuex'
import UcsConfirm from '@/components/modal/UcsConfirm'

export default defineComponent({
  props: ['visible', 'organizationId'],
  components: { UcsConfirm },
  setup (props, { emit }) {
    const state = reactive({
      isShow: props.visible,
      loadingDocuments: false,
      data: {
        totalCount: 0,
        pageSize: 10,
        totalPage: 0,
        currPage: 1,
        list: []
      },
      filter: '',
      keyword: '',
      recovering: false,
      organizationId: props.organizationId === true ? props.organizationId : '',
      completelyDeleteVis: false,
      restoreConfirmVis: false,
      record: null
    })

    const getDocuments = (page, size) => {
      state.loadingDocuments = true
      getDeleteDocumentsApi(
        state.organizationId,
        page,
        size,
        state.filter,
        state.keyword
      )
        .then((res) => {
          state.data = res.result
        })
        .catch((err) => {
          Modal.error({ content: err.msg })
        })
        .finally(() => {
          state.loadingDocuments = false
        })
    }

    onMounted(() => {
      getDocuments(1, pagination.value.pageSize)
    })

    const pagination = computed(() => ({
      total: state.data.totalCount,
      current: state.data.currPage,
      pageSize: state.data.pageSize,
      pageSizeOptions: ['10', '20', '30'],
      showSizeChanger: true,
      size: 'small'
    }))

    const pageChange = (pagination, filters, sorter, { currentDataSource }) => {
      getDocuments(pagination.current, pagination.pageSize)
    }

    const onClose = () => {
      state.isShow = false
    }

    watchEffect(() => {
      emit('update:visible', state.isShow)
    })

    watchEffect(() => {
      state.isShow = props.visible
    })

    const filterElements = [
      {
        label: '문서 이름',
        value: 'documentName'
      },
      {
        label: '서명 참여자',
        value: 'participantNameOrContactInfo'
      },
      {
        label: '서명 요청자',
        value: 'requesterNameOrEmail'
      }
    ]
    state.filter = 'documentName'

    const filterChange = (value) => {
      state.keyword = ''
    }

    const onSearch = () => {
      getDocuments(1, pagination.value.pageSize)
    }

    const restore = () => {
      state.recovering = true
      return restoreDocumentApi(state.record.documentId)
        .then((res) => {
          getDocuments(pagination.value.current, pagination.value.pageSize)
        })
        .catch((err) => {
          Modal.error({ content: err.msg })
        })
        .finally(() => {
          state.recovering = false
          state.restoreConfirmVis = false
        })
    }

    const completelyDelete = (record) => {
      state.completelyDeleteVis = true
      state.record = record
    }

    const restoreConfirm = (record) => {
      state.restoreConfirmVis = true
      state.record = record
    }

    const doCompletelyDelete = () => {
      return completelyDeleteApi(state.record.documentId)
        .then(res => {
          getDocuments(pagination.value.current, pagination.value.pageSize)
        })
        .catch(err => {
          Modal.error({ content: err.msg })
        })
        .finally(() => {
          state.completelyDeleteVis = false
        })
    }

    const store = useStore()
    const isAuthor = (record) => {
      return record.requester.loginId === store.state.user.userInfo.loginId
    }

    return {
      onClose,
      ...toRefs(state),
      pagination,
      pageChange,
      filterElements,
      filterChange,
      onSearch,
      restore,
      completelyDelete,
      restoreConfirm,
      doCompletelyDelete,
      isAuthor
    }
  }
})
</script>
