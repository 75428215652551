<template>
  <UcsModal
    v-model:visible="visible"
    :zIndex="990"
    @close="cancel"
  >
    <template #title>
      <span style="margin-right:0.5rem">클라이언트 {{ isCreate ? '생성' : '수정' }}</span>
    </template>
    <template #content>
      <a-spin :spinning="isLoading">
        <div style="padding: 2rem">
          <a-form ref="formRef" :model="formState" :rules="rules">
            <h3 class="tit">
              클라이언트 이름
            </h3>
            <a-form-item class="form-tit" name="clientName">
              <a-input v-model:value="formState.clientName" class="ele" placeholder="클라이언트 이름을 입력해 주세요"/>
            </a-form-item>
            <a-divider/>
            <!--
            <a-form-item class="form-tit" name="logo">
              <h3 class="tit">
                클라이언트 로고
              </h3>
              <img
                :width="100"
                :height="100"
                src="@\assets\images\payapp_service.png"
              />
            </a-form-item>
            <a-divider />
            -->
            <h3 class="tit">
              리다이렉트 URI
            </h3>
            <a-form-item v-for="(item, index) in formState.redirectUris" :key="item.id" :name="['redirectUris', index, 'redirectUri']" :rules="[
                { required: true, message: 'uri를 입력해 주세요', trigger: 'change' },
              ]"
                         class="form-tit"
            >
              <div class="list">
                <a-input v-model:value="item.redirectUri" class="ele" placeholder="uri를 입력해 주세요" style="width: 80%"/>
                <a-button danger style="width: 15%" @click="removeUri(item)">삭제</a-button>
              </div>
            </a-form-item>
            <div class="flex-s">
              <span class="info">리다이렉트URI는 최대 5개까지 생성할 수 있습니다</span>
              <a-button :disabled="formState.redirectUris.length > 4" @click="createUri">
                <plus-outlined style="color: #00AAFF"/>
                리다이렉트 URI 추가
              </a-button>
            </div>
            <a-divider/>
          </a-form>
          <a-row v-if="isCreate" :gutter="[6, 6]" style="padding: 0 18px 20px">
            <a-col :span="12">
              <a-button :block="true" class="btn" style="color: #8c8c8c;" @click="cancel">취소</a-button>
            </a-col>
            <a-col :span="12">
              <a-button :block="true" :loading="submitting" class="btn" type="primary" @click="confirm">생성</a-button>
            </a-col>
          </a-row>
          <a-row v-else :gutter="[6, 6]" style="padding: 0 18px 20px">
            <a-col :span="8">
              <a-button :block="true" class="btn" style="color: #8c8c8c;" @click="cancel">취소</a-button>
            </a-col>
            <a-col :span="8">
              <a-button :block="true" :loading="removing" class="btn" type="danger" @click="confirmRemoveClient = true">삭제</a-button>
            </a-col>
            <a-col :span="8">
              <a-button :block="true" :loading="submitting" class="btn" type="primary" @click="confirm">수정</a-button>
            </a-col>
          </a-row>
        </div>
      </a-spin>
    </template>
  </UcsModal>
  <UcsConfirm v-if="confirmRemoveClient" :visible="confirmRemoveClient" cancelText="취소" confirmText="확인" @onClickCancel="confirmRemoveClient = false" @onClickConfirm="removeClient">
    <template #cusTitle>
      <h3>클라이언트 삭제</h3>
    </template>
    <template #cusContent>
      클라이언트를 삭제할 경우 복구할 수 없습니다.<br/>
      그래도 진행하시겠습니까?
    </template>
  </UcsConfirm>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import UcsModal from '@/components/common/UcsModal.vue'
import UcsConfirm from '@/components/modal/UcsConfirm'
import { getClientInfo, removeClient as remove, submitClient } from 'api/developer'
import { Modal } from 'ant-design-vue'

export default {
  components: {
    PlusOutlined,
    UcsModal,
    UcsConfirm
  },
  emits: ['reload'],
  setup (props, { emit }) {
    const formRef = ref(null)
    const state = reactive({
      clientId: null,
      isLoading: true,
      isCreate: false,
      visible: false,
      confirmRemoveClient: false,
      submitting: false,
      removing: false,
      rules: {
        clientName: [{
          required: true,
          min: 1,
          message: '이름을 입력해 주세요',
          trigger: 'change'
        }]
        /*
        redirectUris: [
          { required: true, message: 'uri를 입력해 주세요', trigger: 'change' },
          { pattern: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)/, message: '올바른 uri를 입력해 주세요', trigger: 'change' }
        ]
        */
      }
    })

    const formState = reactive({
      clientName: '',
      clientLogo: null,
      redirectUris: []
    })

    const cancel = () => {
      state.visible = false
      formRef.value.clearValidate()
    }

    const show = clientId => {
      state.visible = true
      state.isLoading = true
      state.clientId = clientId
      if (state.clientId === null) {
        formState.clientName = ''
        formState.clientLogo = null
        formState.redirectUris = []
        state.isLoading = false
        state.isCreate = true
      } else {
        state.isCreate = false
        getClientInfo(state.clientId)
          .then(res => {
            formState.clientName = res.result.clientName
            formState.clientLogo = res.result.clientLogo
            formState.redirectUris = res.result.redirectUris
          })
          .catch(() => {
            Modal.error({ content: '클라이언트 조회 실패' })
          })
          .finally(() => {
            state.isLoading = false
          })
      }
    }

    const createUri = () => {
      formState.redirectUris.push({
        redirectUri: ''
      })
    }

    const removeUri = (record) => {
      for (let i = 0; i < formState.redirectUris.length; i++) {
        if (formState.redirectUris[i].id === record.id) {
          formState.redirectUris.splice(i, 1)
        }
      }
    }

    const confirm = async () => {
      state.submitting = true
      try {
        await formRef.value.validateFields()
        submitClient(state.clientId, formState)
          .then(() => {
            state.visible = false
            emit('reload')
          })
          .catch(() => {
            Modal.error({ content: '클라이언트 편집 실패' })
          })
          .finally(() => {
            state.submitting = false
          })
      } catch (error) {
        state.submitting = false
      }
    }

    const removeClient = () => {
      state.removing = true
      remove(state.clientId)
        .then(() => {
          state.visible = false
          state.confirmRemoveClient = false
          emit('reload')
        })
        .catch(() => {
          Modal.error({ content: '클라이언트 삭제 실패' })
        }).finally(() => {
          state.removing = false
        })
    }

    return {
      ...toRefs(state),
      formRef,
      formState,
      show,
      createUri,
      removeUri,
      confirm,
      removeClient,
      cancel
    }
  }

}
</script>
<style lang="less" scoped>
h3.tit {
  text-align: left;
  font-size: 1.5rem;
  font-weight: 500;
}

.list {
  display: flex;
  justify-content: space-between;
}

.ele {
  margin-top: 2px;
}

.info {
  font-weight: 500;
  color: #aaa;
  margin-left: 10px;
}

.btn {
  height: 2.667em;
  font-size: 1.5rem;
}

.flex-s {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
