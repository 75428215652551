<template>
  <div class="info">
    <div class="inner">
      <div class="info-cont">
        <div class="horizon-list">
          <div>
            <div class="list-tit">
              <h4>내 정보</h4>
            </div>
            <ul class="info-list">
              <li v-if="changeNameVisible === false">
                {{ myInformation.name }}
                <div class="btn-inline">
                  <a class="text-blue" @click="showChangeName()">변경하기</a>
                </div>
              </li>
              <li v-else>
                변경할 이름
                <div class="input-group form-control input-group-lg">
                  <input v-model="editingName" maxlength="32" type="text"/>
                  <span
                    :class="(editingName.length === 0 || loading) ? 'input-group-addon btn-inactive' : 'input-group-addon btn-active'"
                    :style="{ 'pointer-events' : ((editingName.length === 0 || loading) ? 'none' : 'auto') }"
                    role="button"
                    @click="handleChangeName"
                  >
                    저장하기
                  </span>
                  <a-spin :spinning="loading"/>
                </div>
              </li>
              <li>
                계정 이메일
                <p>{{ myInformation.email }}</p>
              </li>
              <li v-if="myInformation.team !== ''">
                역할/소속팀
                <p>{{ `${myInformation.role || ''}/${myInformation.team || ''}` }}</p>
              </li>
            </ul>
          </div>
          <div v-if="myInformation.team === ''">
            <div class="list-tit">
              <h4>삭제된 문서</h4>
            </div>
            <ul class="info-list">
              <li>삭제된 문서 목록을 확인하거나, 문서를 복구할 수 있습니다.</li>
              <li>
                <button
                  class="btn btn-blue modal-trigger"
                  data-modal-id="delete-docu"
                  type="button"
                  @click="showRecoverDeletedDoc = true"
                >
                  삭제된 문서 목록 보기
                </button>
              </li>
            </ul>
          </div>
          <div v-else-if="myInformation.team !== '' && myInformation.role !== '최고 관리자'">
            <div class="list-tit">
              <h4>비밀번호</h4>
            </div>
            <ul class="info-list">
              <li v-if="!showPasswordChange">
                <button
                  class="btn btn-blue modal-trigger"
                  type="button"
                  @click="showPasswordChange = true"
                >
                  비밀번호 변경
                </button>
              </li>
              <li v-else>
                <a-form
                  ref="passwordChangeFormRef"
                  :colon="false"
                  :model="changePasswordForm"
                  :rules="passwordRules"
                >
                  <div class="input-wrap">
                    <a-form-item name="inputNewPwd">
                      <a-input
                        v-model:value="changePasswordForm.inputNewPwd"
                        :disabled="isChanging"
                        :lazy="false"
                        place
                        placeholder="새 비밀번호 입력"
                        required type="password"
                        @input="checkPassword"
                      />
                    </a-form-item>
                    <a-form-item name="inputNewConfirmPwd">
                      <a-input
                        v-model:value="changePasswordForm.inputNewConfirmPwd"
                        :disabled="isChanging"
                        :lazy="false"
                        place
                        placeholder="새 비밀번호 다시 입력"
                        required
                        type="password"
                        @input="checkPassword"
                      />
                    </a-form-item>
                  </div>
                  <a-button
                    :disabled="!changePasswordForm.checkPasswordConfirm"
                    :loading="isChanging"
                    class="btn modal-trigger"
                    type="primary"
                    @click="changePassword"
                  >
                    비밀번호 변경 완료
                  </a-button>
                </a-form>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <RecoverDeletedDoc v-model:visible="showRecoverDeletedDoc"/>
</template>

<script>
import { defineComponent, h, onMounted, reactive, ref, toRefs } from 'vue'
import { useStore } from 'vuex'
import { changeSubAccountPassword, setUserName } from 'api/user'
import RecoverDeletedDoc from './recoverDeletedDoc.vue'
import { validPassword } from 'utils/validate'
import { Modal } from 'ant-design-vue'
import router from '@/router'

export default defineComponent({
  components: {
    RecoverDeletedDoc
  },
  setup () {
    const store = useStore()

    const state = reactive({
      myInformation: {
        name: store.state.user.userInfo.name,
        email: store.state.user.userInfo.email,
        role: store.state.organization.organization ? store.state.organization.organization.roleName : '',
        team: store.state.organization.organization ? store.state.organization.organization.teamName : ''
      },
      loading: false,
      showRecoverDeletedDoc: false,
      showPasswordChange: false,
      isChanging: false
    })

    /*
    watch(() => store.state.user.userInfo, (newValue) => {
      state.myInformation.name = newValue.name
      state.myInformation.email = newValue.email
      state.myInformation.role = _value(() => {
        return newValue.organization.roleName
      })
      state.myInformation.team = _value(() => {
        return newValue.organization.teamName
      })
    })
     */

    const updateInfo = () => {
      store.dispatch('userInfo')
    }

    onMounted(() => {
      updateInfo()
    })

    const changeNameVisible = ref(false)
    const editingName = ref('')
    const showChangeName = () => {
      editingName.value = state.myInformation.name
      changeNameVisible.value = true
    }
    const handleChangeName = () => {
      state.loading = true
      if (editingName.value === state.myInformation.name) {
        changeNameVisible.value = false
        state.loading = false
      } else {
        const name = encodeURIComponent(editingName.value)
        setUserName(name)
          .then(() => {
            state.myInformation.name = editingName.value
            updateInfo()
          })
          .finally(() => {
            changeNameVisible.value = false
            state.loading = false
          })
      }
    }

    const changePasswordForm = reactive({
      inputNewPwd: '',
      inputNewConfirmPwd: '',
      checkPasswordConfirm: false
    })

    const passwordRules = {
      inputNewPwd: [
        {
          trigger: 'change',
          validator: async (rule, value) => {
            return validPassword(value)
              ? Promise.resolve()
              : Promise.reject(new Error('영문과 숫자를 섞어 8자리 이상 입력하세요. (특수문자 입력 가능)'))
          }
        }
      ],
      inputNewConfirmPwd: [
        {
          trigger: 'change',
          validator: async (rule, value) => {
            return changePasswordForm.inputNewPwd === value
              ? Promise.resolve()
              : Promise.reject(new Error('새 비밀번호와 일치하지 않습니다.'))
          }
        }
      ]
    }

    const checkPassword = () => {
      changePasswordForm.checkPasswordConfirm =
        changePasswordForm.inputNewPwd &&
        changePasswordForm.inputNewPwd === changePasswordForm.inputNewConfirmPwd &&
        validPassword(changePasswordForm.inputNewPwd)
    }

    const changePassword = () => {
      state.isChanging = true
      changeSubAccountPassword(changePasswordForm.inputNewPwd).then(res => {
        Modal.success({
          content: h('div', [
            h('br', ' '),
            h('p', '비밀번호 변경이 완료되었습니다.'),
            h('p', '보안을 위해 로그아웃 됩니다.'),
            h('p', '새 비밀번호로 다시 로그인 해주세요.')
          ]),
          onOk () {
            store.dispatch('logout')
            router.push({ name: 'Login' })
          }
        })
      }).catch(err => {
        Modal.error({
          content: err.msg
        })
      }).finally(() => {
        state.isChanging = false
      })
    }

    return {
      ...toRefs(state),
      changeNameVisible,
      editingName,
      showChangeName,
      handleChangeName,
      changePasswordForm,
      passwordRules,
      checkPassword,
      changePassword
    }
  }
})
</script>
