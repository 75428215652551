<template>
  <div class="setting">
    <div class="info">
      <div class="inner">
        <div class="info-cont">
          <p class="info-guide">
            이메일 양식을 브랜드에 맞게 커스터마이징하여 사용할 수 있습니다.
          </p>
          <div class="horizon-list">
            <div>
              <div class="list-tit">
                <h4>이메일</h4>
              </div>
              <ul class="info-list">
                <li>
                  서명 요청자와 참여자에게 발송되는 이메일의 로고 이미지, 스타일, 소개글 등을 변경할 수 있습니다.
                </li>
              </ul>
            </div>
            <div>
              <ul class="info-list">
                <li>
                  <dl>
                    <dt class="col-3">
                      로고이미지
                    </dt>
                    <dd>
                      <div class="form-box service-logo">
                        <img :src="email.logo" alt="LOGO"/>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt class="col-3">
                      로고링크
                    </dt>
                    <dd>{{ email.address }}</dd>
                  </dl>
                  <dl>
                    <dt class="col-3">
                      상단/하단바 색상
                    </dt>
                    <dd>
                      <span
                        :style="{'border-color': 'gray', 'background': email.barColor}"
                        class="label-color"
                      />
                      {{ email.barColor }}
                    </dd>
                  </dl>
                  <dl>
                    <dt class="col-3">
                      버튼 색상
                    </dt>
                    <dd>
                      <span
                        :style="{'border-color': 'gray', 'background': email.buttonColor}"
                        class="label-color"
                      />
                      {{ email.buttonColor }}
                    </dd>
                  </dl>
                  <dl>
                    <dt class="col-3">
                      버튼 글자 색상
                    </dt>
                    <dd>
                      <span
                        :style="{'border-color': 'gray', 'background': email.textColor}"
                        class="label-color"
                      />
                      {{ email.textColor }}
                    </dd>
                  </dl>
                </li>
                <li>
                  <button
                    v-show="!isMobile"
                    class="btn btn-blue modal-trigger"
                    data-modal-id="style-change"
                    type="button"
                    @click="handleChangeEmailInfo"
                  >
                    변경하기
                  </button>
                </li>
              </ul>
            </div>
            <div>
              <div class="list-tit">
                <h4>발신자 정보</h4>
              </div>
              <ul class="info-list">
                <li>
                  서명 요청자와 참여자에게 발송되는 이메일의 보내는 사람 이름을 변경할 수 있습니다.
                </li>
                <li>
                  <a-space direction="vertical">
                    <label v-if="changeNameMode" class="form-tit" for="sendUser">보내는 사람 이름</label>
                    <a-input
                      v-if="changeNameMode"
                      id="sendUser"
                      v-model:value="senderName"
                      :lazy="false"
                      class="form-control"
                      placeholder=""
                      type="text"
                      @input="checkValidate"
                    />
                    <div class="error-info text-red"> {{ errorInfo }}</div>
                    <a-button v-if="changeNameMode" :block="true" :loading="isChangingName" size="large" style="padding-left: 20px; padding-right: 20px; margin-top: 20px; font-size: 1.5rem;"
                              type="primary"
                              @click="handleChangeName">저장하기
                    </a-button>
                    <a-space v-if="!changeNameMode" align="center" direction="horizontal">
                      <h4 style="margin: 0">{{ senderName }}</h4>
                      <div class="btn-inline">
                        <a class="text-blue" @click="changeNameMode = true">변경하기</a>
                      </div>
                    </a-space>
                  </a-space>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <EmailInfo v-model:visible="emailInfoVisible" v-bind="email" @changed="handleEmailInfoChanged" @close="handleCloseEmailInfo"/>
</template>

<script>
import { defineComponent, onMounted, ref, toRefs, watch } from 'vue'
import { Message, Modal } from 'ant-design-vue'
import EmailInfo from './email-info.vue'
import { info, setSenderName } from 'api/settings'
import { useStore } from 'vuex'
import { useMediaQuery } from '@vueuse/core'

export default defineComponent({
  components: {
    EmailInfo
  },
  setup () {
    onMounted(() => {
      update()
    })

    const errorInfo = ref('')

    const isMobile = useMediaQuery('(max-width: 768px)')

    const update = () => {
      info().catch((err) => {
        Message.error(err)
      })
    }

    const emailInfoVisible = ref(false)
    const handleChangeEmailInfo = () => {
      emailInfoVisible.value = true
    }
    const handleCloseEmailInfo = () => {
      emailInfoVisible.value = false
    }

    const changeNameMode = ref(false)
    const isChangingName = ref(false)
    const handleChangeName = () => {
      if (errorInfo.value.length > 0) return false
      isChangingName.value = true
      const name = encodeURIComponent(senderName.value)
      setSenderName(name)
        .then(() => {
          changeNameMode.value = false
        })
        .catch(error => {
          Modal.error({
            content: error.msg
          })
        })
        .finally(() => {
          isChangingName.value = false
          update()
        })
    }

    const handleEmailInfoChanged = (logo, address, desc, barColor, buttonColor, textColor) => {

    }

    const checkValidate = () => {
      if (senderName.value.length <= 10) {
        const reg = /^(?:[a-zA-Z\d가-힣ㄱ-ㅎㅏ-ㅣ]{1,10})$/igm
        if (!senderName.value.match(reg)) {
          errorInfo.value = '10자 이내의 한글, 영어, 숫자로 입력해 주세요'
        } else {
          errorInfo.value = ''
        }
      } else {
        errorInfo.value = '10자 이내의 한글, 영어, 숫자로 입력해 주세요'
      }
    }

    const store = useStore()

    const senderName = ref(store.state.settings.sender.name)
    watch(() => store.state.settings.sender.name, (newValue) => {
      senderName.value = newValue
    })

    return {
      ...toRefs(store.state.settings),
      errorInfo,
      checkValidate,
      changeNameMode,
      isChangingName,
      senderName,
      emailInfoVisible,
      handleChangeEmailInfo,
      handleCloseEmailInfo,
      handleChangeName,
      handleEmailInfoChanged,
      isMobile
    }
  }
})
</script>
