<template>
  <UcsModal
    v-model:visible="visible"
    :width="1200"
    :zIndex="990"
    @close="close"
  >
    <template #title>
      <span style="margin-right:0.5rem">웹훅 로그</span>
    </template>
    <template #content>
      <div style="padding: 0.5em 2em 1.5em;">
        <div class="search">
          <div>
            <div class="item">
              이벤트타입
              <a-select v-model:value="eventType" :options="eventTypeOptions"/>
            </div>
            <div class="item">
              전송결과
              <a-select v-model:value="status" :options="statusOptions"/>
            </div>
            <div class="item">
              날짜
              <a-date-picker v-model:value="start" :disabled-date="disabledStart" :inputReadOnly="true" format="YYYY-MM-DD" placeholder="시작일"/>
              ~
              <a-date-picker v-model:value="end" :disabled-date="disabledEnd" :inputReadOnly="true" format="YYYY-MM-DD" placeholder="종료일"/>
            </div>
          </div>
          <div class="item">
            <a-button type="primary" @click="fetchData(webhookId)">검색</a-button>
          </div>
        </div>
        <a-table
          :columns="columns"
          :data-source="data"
          :expandRowByClick="true"
          :expandedRowKeys="expandedRowKeys"
          :loading="isLoading"
          :pagination="pagination"
          :row-key="(record) => record.webhookLogId"
          @change="handleTableChange"
          @expand="onRowExpand"
        >
          <template #eventType="{record}">
            <a-tag v-if="record.eventType==='sign_creating'" :color="checkResult(record.resultCode) ? 'processing' : 'error'">
              서명 생성
            </a-tag>
            <a-tag v-if="record.eventType==='signing_canceled'" :color="checkResult(record.resultCode) ? 'processing' : 'error'">
              서명 요청 취소
            </a-tag>
            <a-tag v-if="record.eventType==='signing_completed'" :color="checkResult(record.resultCode) ? 'processing' : 'error'">
              서명 완료
            </a-tag>
            <a-tag v-if="record.eventType==='signing_completed_all'" :color="checkResult(record.resultCode) ? 'processing' : 'error'">
              모든 서명 완료
            </a-tag>
          </template>
          <template #resultCode="{record}">
            <p>{{ checkResult(record.resultCode) ? '성공' : '실패' }}</p>
            <p>{{ record.resultCode }}</p>
          </template>
          <template #document="{record}">
            <p>문서 ID : {{ record.document.documentId }}</p>
            <p>문서명 : {{ record.document.name }}</p>
          </template>
          <template #participant="{record}">
            <div v-if="record.participant !== null && record.participant !== undefined">
              <p>참여자 ID : {{ record.participant.participantId }}</p>
              <p>참여자 이름 : {{ record.participant.name }}</p>
              <p>참여자 연락처 : {{ record.participant.signingContactInfo }}</p>
              <p>참여자의 서명 순서 : {{ record.participant.signingOrder }}</p>
            </div>
          </template>
          <template #expandedRowRender="{record}">
            <div style="display: flex; align-items: flex-start">
              <a-tag color="warning" style="margin-left:4.8rem">전송 데이터</a-tag>
              <p style="margin-left:6.3rem; max-height:15rem; overflow-y: auto">
                {{ record.content }}
              </p>
            </div>
            <div style="display: flex; align-items: flex-start">
              <a-tag color="warning" style="margin-left:4.8rem">응답 메시지</a-tag>
              <p style="margin-left:6.3rem; max-height:15rem; overflow-y: auto">
                {{ record.resultMsg }}
              </p>
            </div>
          </template>
        </a-table>
      </div>
    </template>
  </UcsModal>
</template>

<script>
import { onUnmounted, reactive, ref, toRefs } from 'vue'
import UcsModal from '@/components/common/UcsModal.vue'
import { getWebhookLog } from 'api/developer'
import { Modal } from 'ant-design-vue'
import { FormatTime } from '@/utils/util'

export default {
  components: {
    UcsModal
  },
  setup () {
    const state = reactive({
      data: null,
      webhookId: null,
      pagination: {
        showQuickJumper: true,
        pageSize: 5
      },
      isLoading: true,
      visible: false
    })

    const columns = [
      {
        title: '이벤트타입',
        key: 'eventType',
        slots: {
          customRender: 'eventType'
        }
      },
      {
        title: '전송결과',
        key: 'resultCode',
        slots: {
          customRender: 'resultCode'
        }
      },
      {
        title: '문서',
        key: 'document',
        slots: {
          customRender: 'document'
        }
      },
      {
        title: '참여자',
        key: 'participant',
        slots: {
          customRender: 'participant'
        }
      },
      {
        title: '요청시기',
        dataIndex: 'createdAt',
        customRender: ({ record }) => {
          return FormatTime(record.createdAt)
        },
        key: 'createdAt'
      }
    ]

    const eventTypeOptions = [
      {
        value: 'all',
        label: '모든 상태'
      },
      {
        value: 'sign_creating',
        label: '서명 생성'
      },
      {
        value: 'signing_canceled',
        label: '서명 요청 취소'
      },
      {
        value: 'signing_completed',
        label: '서명 완료'
      },
      {
        value: 'signing_completed_all',
        label: '모든 서명 완료'
      }
    ]
    const statusOptions = [
      {
        value: 'all',
        label: '모든 상태'
      },
      {
        value: 'success',
        label: '성공'
      },
      {
        value: 'fail',
        label: '실패'
      }
    ]
    const formState = reactive({
      page: 1,
      limit: 5,
      eventType: 'all',
      status: 'all',
      start: null,
      end: null
    })

    const close = () => {
      state.visible = false
      state.record = null
      formState.page = 1
      formState.eventType = 'all'
      formState.status = 'all'
      formState.start = null
      formState.end = null
    }

    const show = webhookId => {
      state.visible = true
      state.webhookId = webhookId
      fetchData(state.webhookId)
    }

    const fetchData = (webhookId) => {
      state.data = null
      state.isLoading = true
      if (state.pagination.current) {
        formState.page = state.pagination.current
      }
      const params = {
        page: formState.page,
        limit: formState.limit,
        eventType: formState.eventType,
        status: formState.status,
        start: formState.start !== null ? formState.start.format('YYYY-MM-DD') : null,
        end: formState.end !== null ? formState.end.format('YYYY-MM-DD') : null
      }

      if (params.page === null) delete params.page
      if (params.limit === null) delete params.limit
      if (params.eventType === null) delete params.eventType
      if (params.status === null) delete params.status
      if (params.start === null) delete params.start
      if (params.end === null) delete params.end

      getWebhookLog(webhookId, params)
        .then(res => {
          const result = res.result.records
          state.pagination.total = result.totalCount
          state.pagination.current = result.currPage
          state.pagination.pageSize = result.pageSize
          state.data = result.list
          state.data.forEach(item => {
            item.key = item.webhookLogId
          })
          const pager = { ...state.pagination }
          pager.current = state.pagination.current
          pager.pageSize = state.pagination.pageSize
          state.pagination = pager
          state.isLoading = false
        }).catch(error => {
          console.error(error)
          Modal.error({
            content: '조회 에러',
            onOk () {
              close()
            }
          })
        })
    }

    const confirm = () => {
      state.visible = false
    }

    const checkResult = (resultCode) => {
      return resultCode >= 0 && resultCode < 300
    }

    const expandedRowKeys = ref([])
    const onRowExpand = (expanded, record) => {
      expandedRowKeys.value = []
      if (expanded) {
        setTimeout(() => {
          expandedRowKeys.value.push(record.webhookLogId)
        }, 0)
      }
    }

    const handleTableChange = (pagination) => {
      const pager = { ...state.pagination }
      pager.current = pagination.current
      state.pagination = pager
      fetchData(state.webhookId)
    }

    const disabledStart = current => {
      const today = current.diff(FormatTime(null, 'YYYYMMDD'), 'day') > 0
      return today
    }

    const disabledEnd = current => {
      const today = current.diff(FormatTime(null, 'YYYYMMDD'), 'day') > 0
      const selected = formState.start != null && current.diff(formState.start.format('YYYYMMDD'), 'hour') < 0
      return today || selected
    }

    onUnmounted(() => {
      state.record = null
      formState.page = 1
      formState.eventType = 'all'
      formState.status = 'all'
      formState.start = null
      formState.end = null
    })

    return {
      ...toRefs(state),
      ...toRefs(formState),
      eventTypeOptions,
      statusOptions,
      columns,
      show,
      fetchData,
      close,
      confirm,
      checkResult,
      onRowExpand,
      expandedRowKeys,
      handleTableChange,
      disabledStart,
      disabledEnd
    }
  }

}
</script>
<style lang="less" scoped>
h3.tit {
  text-align: left;
  font-size: 1.5rem;
  font-weight: 500;
}

.search {
  text-align: right;
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 1rem 0;

  .item {
    float: left;
    margin-left: 3rem;

    &:first-child {
      margin-left: 0
    }

    .ant-select {
      margin-left: 1rem
    }

    .ant-picker {
      margin: 0 1rem
    }
  }
}
</style>
