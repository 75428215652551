<template>
  <div v-if="isLoaded">
    <div class="inner">
      <div v-if="isDeveloper === false" class="info-cont">
        <div class="info-guide">
          <span>유캔싸인 API로 기업 내 서비스에 자체 계약시스템을 구축하세요!</span>
          <a @click="requestDeveloper">개발자 등록하기</a>
        </div>
      </div>
      <div v-else class="info-cont">
        <div class="info-guide">
          <span>유캔싸인 API로 기업 내 서비스에 자체 계약시스템을 구축하세요!</span>
          <a target="_blank" @click="router.push({ name: 'Dev-Index' })">개발자 센터</a>
        </div>
        <a-radio-group v-model:value="developerTab">
          <a-radio-button value="TOKEN">API KEY</a-radio-button>
          <a-radio-button value="CLIENT">클라이언트</a-radio-button>
          <a-radio-button value="WEBHOOK">웹훅(Webhook)</a-radio-button>
        </a-radio-group>
        <div class="horizon-list">
          <div v-show="developerTab === 'TOKEN'">
            <div class="list-tit">
              <h4>API KEY</h4>
            </div>
            <div class="description">
              <p>API KEY는 유캔싸인API 요청을 위해 이용할 토큰을 발급받기 위한 키입니다.</p>
              <p>자세한 내용은 <a style="color: #6063ff" @click="router.push({ name: 'Dev-Auth' })">여기를 확인해 주시기 바랍니다.</a></p>
            </div>
            <ul class="info-list api-list">
              <li>
                <p>
                  <a-input :value="apiKey_format" disabled readolny style="width:170px;"/>
                </p>
                <a-popover :visible="isCopied" trigger="click">
                  <template #content>
                    <p>클립보드에 저장되었습니다.</p>
                  </template>
                  <a-button @click="copyApiKey">
                    <copy-outlined/>
                    복사
                  </a-button>
                </a-popover>
              </li>
              <li>
                <a-button @click="reissueApiKey">
                  <sync-outlined/>
                  API KEY 갱신
                </a-button>
              </li>
            </ul>
          </div>
          <!--
          <div>
            <div class="list-tit">
              <h4>API 호출 횟수</h4>
            </div>
            <div class="description">
              API 토큰을 통해 API를 호출한 횟수를 표시합니다.
            </div>
            <ul class="info-list">
              <li>
                <a-progress :percent="totalCall/100" :show-info="false"/>
                {{ totalCall }}회
                <a-button type="link" v-if="totalCallLoading === false" @click="refreshTotalCall">
                  <sync-outlined />
                </a-button>
                <a-button type="link" v-else>
                  <sync-outlined spin />
                </a-button>
              </li>
            </ul>
          </div>
          -->
          <div v-show="developerTab === 'CLIENT'">
            <div class="list-tit">
              <h4>클라이언트</h4>
            </div>
            <div class="description">
              <p>클라이언트는 회원님이 아닌 회원님의 고객 개개인에게 유캔싸인 접근권한을 할당하기 위해 마련된 기능입니다.</p>
              <p>해당 기능에 대한 자세한 내용은 <a style="color: #6063ff" @click="router.push({ name: 'Dev-Oauth' })">여기를 확인해 주시기 바랍니다.</a></p>
              <!-- <br/>
              <p>먼저 클라이언트와 리다이렉트 URI를 등록한 이후, 고객에게 oauth 로그인 페이지로 이동시켜, 인증 요청을 합니다.</p>
              <p>oauth 로그인 주소는 다음과 같습니다.</p>
              <div class="grey-box">https://app.ucansign.com/user/oauth/login?client_id={클라이언트 ID}&redirect_uri={리다이렉트 URI(URL인코딩 필수)}&state={CSRF검증용 state값}</div>
              <br/>
              <p>클라이언트 ID값이 유효하지 않거나, 리다이렉트 URI가 해당 클라이언트에 등록되어 있지 않으면 로그인은 진행되지 않습니다.</p>
              <p>로그인이 완료되면 고객의 Access토큰을 얻을 수 있는 인증 코드를 부여받게 되고, 리다이렉트 URI의 쿼리 파라미터에 코드가 포함된 채로 페이지가 이동됩니다.</p>
              <br/>
              <div class="grey-box">{리다이렉트 URI}?code={코드값}&state={링크 생성시 추가한 state값}</div>
              <br/>
              <p>코드를 통해 Access토큰을 할당받는 방법, 각 쿼리 파라미터의 역할 등 기타 세부적인 내용들은 <a href="https://documenter.getpostman.com/view/20616084/2s7YfHhcGY#e469be16-6ff8-4ac9-80f0-2ac5d6a9602a" target="_blank" style="color: #6063ff">여기를 확인해 주시기 바랍니다.</a></p>
              <p>할당받은 Access액세스 토큰을 이용해, API요청시 헤더에 'Authorization: Bearer {Access액세스 토큰}' 을 입력해 주시기 바랍니다.</p>
              <p>단, 회원님이 아닌 고객 개개인의 리소스를 이용하기 때문에, 회원님의 웹훅 호출은 이용할 수 없습니다.</p> -->
              <br/>
            </div>
            <ul class="info-list">
              <li>
                <div class="flex-s">
                  <span class="info">클라이언트는 최대 5개까지 생성할 수 있습니다</span>
                  <a-button :disabled="clients.length > 4" @click="showClientEdit(null)">
                  <span class="ico-plus">
                    <svg
                      aria-hidden="true"
                      data-icon="plus"
                      fill="#1890ff"
                      focusable="false"
                      height="0.75em"
                      viewBox="64 64 896 896"
                      width="0.75em"
                    >
                      <path
                        d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"
                      ></path>
                      <path
                        d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"
                      ></path>
                    </svg>
                  </span>
                    클라이언트 추가
                  </a-button>
                </div>
                <a-table :columns="clientColumns" :dataSource="clients" :pagination="false">
                  <template #action="{record}">
                    <div class="btn-list">
                      <a-button class="action-btn" @click="showClientInfo(record.clientId)">정보</a-button>
                      <a-button class="action-btn" @click="showClientEdit(record.clientId)">수정</a-button>
                      <!--<a-button class="action-btn" @click="showClientLog(record)" disabled>사용로그</a-button>-->
                    </div>
                  </template>
                </a-table>
              </li>
            </ul>
          </div>
          <div v-show="developerTab === 'WEBHOOK'">
            <div class="list-tit">
              <h4>웹훅(Webhook)</h4>
            </div>
            <div class="description">
              <p>웹훅은 유캔싸인에서 특정 이벤트가 발생할 때, 회원님께서 설정하신 URL로 POST 요청을 보내는 기능입니다.</p>
              <p>이벤트는 총 4가지로 [서명 생성, 서명 요청 취소, 서명 완료, 모든 서명 완료]가 있습니다.</p>
              <p>해당 기능에 대한 자세한 설명은 <a style="color: #6063ff" @click="router.push({ name: 'Dev-Webhook' })">여기를 확인해 주시기 바랍니다.</a></p>
            </div>
            <ul class="info-list">
              <li>
                <div class="flex-s">
                  <span class="info">웹훅은 최대 5개까지 생성할 수 있습니다</span>
                  <a-button :disabled="webhooks.length > 4" @click="showWebhookEdit(null)">
                  <span class="ico-plus">
                    <svg
                      aria-hidden="true"
                      data-icon="plus"
                      fill="#1890ff"
                      focusable="false"
                      height="0.75em"
                      viewBox="64 64 896 896"
                      width="0.75em"
                    >
                      <path
                        d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"
                      ></path>
                      <path
                        d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"
                      ></path>
                    </svg>
                  </span>
                    웹훅 추가
                  </a-button>
                </div>
                <a-table :columns="webhookColumns" :dataSource="webhooks" :pagination="false">
                  <template #webhook="{record}">
                    {{ record.name }}
                    <p>{{ record.url }}</p>
                    <table class="subscribe-list">
                      <tr class="list-header">
                        <td>구독됨</td>
                        <td>구독안됨</td>
                      </tr>
                      <tr class="list-content">
                        <td>
                          <a-tag v-if="record.eventSignCreating" color="success">
                            <CheckCircleOutlined/>
                            서명 생성
                          </a-tag>
                          <a-tag v-if="record.eventSigningCanceled" color="success">
                            <CheckCircleOutlined/>
                            서명 요청 취소
                          </a-tag>
                          <a-tag v-if="record.eventSigningCompleted" color="success">
                            <CheckCircleOutlined/>
                            서명 완료
                          </a-tag>
                          <a-tag v-if="record.eventSigningCompletedAll" color="success">
                            <CheckCircleOutlined/>
                            모든 서명 완료
                          </a-tag>
                        </td>
                        <td>
                          <a-tag v-if="!record.eventSignCreating" color="error">
                            <CloseCircleOutlined/>
                            서명 생성
                          </a-tag>
                          <a-tag v-if="!record.eventSigningCanceled" color="error">
                            <CloseCircleOutlined/>
                            서명 요청 취소
                          </a-tag>
                          <a-tag v-if="!record.eventSigningCompleted" color="error">
                            <CloseCircleOutlined/>
                            서명 완료
                          </a-tag>
                          <a-tag v-if="!record.eventSigningCompletedAll" color="error">
                            <CloseCircleOutlined/>
                            모든 서명 완료
                          </a-tag>
                        </td>
                      </tr>
                    </table>
                  </template>
                  <template #action="{record}">
                    <div class="btn-list">
                      <a-button class="action-btn" @click="showWebhookEdit(record.webhookId)">수정</a-button>
                      <a-button class="action-btn" @click="showWebhookLog(record.webhookId)">로그</a-button>
                    </div>
                  </template>
                </a-table>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ClientInfoModal ref="clientInfoModal"/>
  <ClientEditModal ref="clientEditModal" @reload="fetchData()"/>
  <WebhookEditModal ref="webhookEditModal" @reload="fetchData()"/>
  <WebhookLogModal ref="webhookLogModal"/>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { CheckCircleOutlined, CloseCircleOutlined, CopyOutlined, SyncOutlined } from '@ant-design/icons-vue'
import { getDeveloperInfo, reissueApiKey as reissueKey, requestDeveloper as register } from 'api/developer'
import ClientInfoModal from './clientInfoModal'
import ClientEditModal from './clientEditModal'
import WebhookEditModal from './webhookEditModal'
import WebhookLogModal from './webhookLogModal'
import { Modal } from 'ant-design-vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    CopyOutlined,
    SyncOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    ClientInfoModal,
    ClientEditModal,
    WebhookEditModal,
    WebhookLogModal
  },

  setup () {
    const clientInfoModal = ref(null)
    const clientEditModal = ref(null)
    const webhookEditModal = ref(null)
    const webhookLogModal = ref(null)
    const router = useRouter()
    const state = reactive({
      isLoaded: false,
      isDeveloper: true,
      apiKey: '',
      apiKey_format: '',
      clients: null,
      webhooks: null,
      isCopied: false,
      totalCall: 0,
      totalCallLoading: false,
      developerTab: 'TOKEN'
    })

    const clientColumns = [
      {
        title: '클라이언트 이름',
        dataIndex: 'clientName',
        key: 'clientName',
        width: '90%'
      },
      {
        dataIndex: 'action',
        key: 'action',
        slots: {
          customRender: 'action'
        },
        width: '10%'
      }
    ]

    const webhookColumns = [
      {
        title: '웹훅',
        dataIndex: 'webhook',
        key: 'webhook',
        slots: {
          customRender: 'webhook'
        },
        width: '90%'
      },
      {
        dataIndex: 'action',
        key: 'action',
        slots: {
          customRender: 'action'
        },
        width: '10%'
      }
    ]

    const fetchData = () => {
      getDeveloperInfo().then(res => {
        state.apiKey = res.result.apiKey
        state.apiKey_format = state.apiKey.substring(0, 8) + '***********'
        state.clients = res.result.clients
        state.webhooks = res.result.webhooks
        state.totalCall = res.result.totalCall
        state.isDeveloper = true
      }).catch(() => {
        // 개발자 정보가 없을 경우 별도의 페이지 열기
        state.isDeveloper = false
      }).finally(() => {
        state.isLoaded = true
      })
    }

    // 개발자정보 불러오기
    onMounted(() => {
      fetchData()
    })

    // 개발자 신청
    const requestDeveloper = () => {
      register().then(() => {
        router.go(0)
      }).catch(() => {
        Modal.error({ content: '개발자 등록에 실패했습니다' })
      })
    }

    // 키 복사
    const copyApiKey = () => {
      const $textarea = document.createElement('textarea')
      document.body.appendChild($textarea)
      $textarea.value = state.apiKey
      $textarea.select()
      document.execCommand('copy')
      document.body.removeChild($textarea)
      showMention()
    }

    // 키 재발급
    const reissueApiKey = () => {
      reissueKey().then(() => {
        fetchData()
        Modal.success({ content: '재발급 완료' })
      }).catch(() => {
        Modal.error({ content: '재발급 실패' })
      })
    }

    // 복사 버튼 누를시 팝업 띄우기
    const showMention = () => {
      state.isCopied = true
      setTimeout(() => {
        state.isCopied = false
      }, 1500)
    }

    // API 호출횟수 동기화
    const refreshTotalCall = () => {
      state.totalCallLoading = true
      getDeveloperInfo().then(res => {
        state.totalCall = res.result.totalCall
      }).catch(() => {
        Modal.error({ content: '갱신 실패' })
      }).finally(() => {
        state.totalCallLoading = false
      })
    }

    const showClientInfo = (clientId) => {
      clientInfoModal.value.show(clientId)
    }

    const showClientEdit = (clientId) => {
      clientEditModal.value.show(clientId)
    }

    const showWebhookEdit = (webhookId) => {
      webhookEditModal.value.show(webhookId)
    }

    const showWebhookLog = (webhookId) => {
      webhookLogModal.value.show(webhookId)
    }

    const showClientLog = client => {
      console.log('log')
    }

    const isSubscribing = record => {
      return record ? CheckCircleOutlined : CloseCircleOutlined
    }

    const openApiDoc = () => {
      window.open('https://documenter.getpostman.com/view/20616084/2s7YfHhcGY', '_blank')
    }

    return {
      ...toRefs(state),
      router,
      clientInfoModal,
      clientEditModal,
      webhookEditModal,
      webhookLogModal,
      clientColumns,
      webhookColumns,
      fetchData,
      requestDeveloper,
      copyApiKey,
      reissueApiKey,
      showMention,
      refreshTotalCall,
      showClientInfo,
      showClientEdit,
      showClientLog,
      showWebhookEdit,
      showWebhookLog,
      isSubscribing,
      openApiDoc
    }
  }
})
</script>

<style lang="less" scoped>
.apidoc {
  text-align: center;

  .apidoc-btn {
    font-size: 24px;
    width: 480px;
    height: 120px;
    display: inline-block;
  }
}

.btn-list {
  display: flex;

  .ant-btn {
    font-size: 1.3rem;

    & + .ant-btn {
      margin-left: 0.5rem
    }
  }
}

.action-btn {
  width: 100%;
}

.info {
  font-weight: 400;
  color: #aaa;
  font-size: 1.4rem;
}

.description {
  padding-bottom: 42px;
  font-size: 14px;
  color: #555;

  .grey-box {
    background-color: #333;
    color: #fff;
    font-family: 'Fira Sans';
    border-radius: 0.5rem;
    padding: 1rem 1.5rem
  }
}

.info-list {
  .ant-table-wrapper {
    margin-top: 1rem
  }
}

.api-list {
  li {
    display: flex;
    align-items: center;

    p {
      margin: 0
    }

    button {
      margin-left: 0.5rem
    }
  }
}

.setting .horizon-list > div + div {
  border-top: 0
}

.ant-radio-group {
  margin-top: 3rem;
}

.ant-radio-button-wrapper {
  width: 15rem;
  text-align: center;
  height: 36px;
  line-height: 35px
}

.info-guide {
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    display: inline-block;
    background: #fff;
    height: 36px;
    line-height: 35px;
    width: 10rem;
    border-radius: 2px;
    border: 1px solid #d9effa;
    font-size: 1.3rem;
    text-align: center;

    &:hover {
      border: 1px solid #1890ff
    }
  }
}

.flex-s {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-tag {
  margin-top: 1rem
}

.subscribe-list {
  width: 100%;
  margin-top: 10px;

  .list-header td {
    width: 50%;
    font-weight: 600;
  }
}

</style>
<style lang="less">
.horizon-list {
  .info-list {
    .ant-table-tbody > tr > td {
      vertical-align: middle;
    }
  }
}
</style>
