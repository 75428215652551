<style lang="less" scoped>
.header {
  height: 62px;
  padding: 0px 25px 0px 25px;
  background: rgb(240, 242, 244);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  font-size: 17px;
  font-weight: 500;
  flex: 1
}

.content {
  display: flex;
  flex-direction: row;
}

.form-group {
  padding-bottom: 20px;
}

.preview-area {
  overflow: auto;
  height: 600px;
}
</style>

<template>
  <a-modal :bodyStyle="{ padding: '0px' }" :closable="false" :footer="null" centered width="1200px">
    <div class="header">
      <h2 class="title">이메일 스타일</h2>
      <span class="close-btn" role="button" @click="handleCancel"/>
    </div>
    <div class="content">
      <div class="table-group custom-wrap">
        <div class="col-4">
          <div class="modal-left">
            <li class="form-group">
              <label class="form-tit">
                <span class="mr10"
                ><span style="vertical-align: inherit"
                ><span style="vertical-align: inherit">로고 이미지</span></span
                ></span
                >
                <a-upload
                  :before-upload="handleChangeLogo"
                  :showUploadList="false"
                  accept=".png"
                >
                  <a-button
                    :loading="isUploadingImage"
                    class="text-blue text-line modal-trigger"
                    data-modal-id="logo-upload"
                    size="small"
                    type="text"
                  >
                    <span style="vertical-align: inherit"
                    ><span style="vertical-align: inherit">변경하기</span></span
                    ><i class="arrow arrow-md arrow-blue right"></i>
                  </a-button>
                </a-upload>
              </label>
              <div class="form-box service-logo">
                <img :src="logo" alt="LOGO"/>
              </div>
            </li>
            <!-- //로고 이미지 -->
            <li class="form-group">
              <label class="form-tit" for="logoLink"
              ><span style="vertical-align: inherit"
              ><span style="vertical-align: inherit">로고 링크</span></span
              ></label
              >
              <a-input
                id="logoLink"
                v-model:value="address"
                class="form-control"
                placeholder=""
                type="url"
              />
            </li>
            <!-- //로고 링크 -->
            <!--
            <div class="form-group">
              <label for="explain" class="form-tit"
                ><font style="vertical-align: inherit"
                  ><font style="vertical-align: inherit">{{
                    $t("settings.customize.info.desc_title")
                  }}</font></font
                ></label
              >
              <a-input
                id="explain"
                type="url"
                placeholder=""
                v-model:value="desc"
                class="form-control"
                :disabled="!funcIsEnable('brand-customizing')"
              />
            </div>
            -->
            <!-- //설명글 -->
            <li class="form-group">
              <label class="form-tit" for="explain"
              ><span style="vertical-align: inherit"
              ><span style="vertical-align: inherit">상단/하단바 색상</span></span
              ></label
              >
              <a-popover v-if="funcIsEnable('brand-customizing')" placement="rightTop" trigger="click">
                <template #content>
                  <Sketch v-model="barColor" :disable-alpha='true'/>
                </template>
                <div class="form-box">
                  <span
                    :style="{ 'border-color': 'gray', background: barColor }"
                    class="label-color"
                    role="button"
                  ></span>
                  <span>
                    {{ barColor }}
                  </span>
                </div>
              </a-popover>
              <div v-else class="form-box">
                <span
                  :style="{ 'border-color': 'gray', background: barColor }"
                  class="label-color"
                  role="button"
                ></span>
                <span>
                  {{ barColor }}
                </span>
              </div>
            </li>
            <!-- //상단/하단 바 색상 -->
            <li class="form-group">
              <label class="form-tit" for="explain"
              ><span style="vertical-align: inherit"
              ><span style="vertical-align: inherit">버튼 색상</span></span
              ></label
              >
              <a-popover v-if="funcIsEnable('brand-customizing')" placement="rightTop" trigger="click">
                <template #content>
                  <Sketch v-model="buttonColor" :disable-alpha='true'/>
                </template>
                <div class="form-box">
                  <span
                    :style="{ 'border-color': 'gray', background: buttonColor }"
                    class="label-color"
                    role="button"
                  ></span>
                  <span>{{ buttonColor }}</span>
                </div>
              </a-popover>
              <div v-else class="form-box">
                <span
                  :style="{ 'border-color': 'gray', background: buttonColor }"
                  class="label-color"
                  role="button"
                ></span>
                <span>{{ buttonColor }}</span>
              </div>
            </li>
            <!-- //버튼 색상 -->
            <li class="form-group">
              <label class="form-tit" for="explain"
              ><span style="vertical-align: inherit"
              ><span style="vertical-align: inherit">버튼 글자 색상</span></span
              ></label
              >
              <a-popover v-if="funcIsEnable('brand-customizing')" placement="rightTop" trigger="click">
                <template #content>
                  <Sketch v-model="textColor" :disable-alpha='true'/>
                </template>
                <div class="form-box">
                  <span
                    :style="{ 'border-color': 'gray', background: textColor }"
                    class="label-color"
                    role="button"
                  ></span>
                  <span>{{ textColor }}</span>
                </div>
              </a-popover>
              <div v-else class="form-box">
                <span
                  :style="{ 'border-color': 'gray', background: textColor }"
                  class="label-color"
                  role="button"
                ></span>
                <span>{{ textColor }}</span>
              </div>
            </li>
            <!-- //버튼 색상 -->
          </div>
        </div>
        <div class="col-8">
          <div class="modal-right">
            <div class="preview-area">
              <table
                border="0"
                cellpadding="0"
                cellspacing="0"
                style="
                  border-collapse: collapse;
                  table-layout: fixed;
                  width: 650px;
                  margin: auto;
                  margin-top: 10px;
                "
              >
                <tbody>
                <tr>
                  <td
                    style="
                        border-collapse: collapse;
                        color: #2e3949;
                        font-size: 15px;
                        letter-spacing: -0.04em;
                        line-height: 1;
                      "
                  >
                    <table
                      bgcolor="#ffffff"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      style="
                          border-collapse: collapse;
                          margin-bottom: 12px;
                          outline: 3px solid #f5f8fa;
                          table-layout: fixed;
                        "
                      width="100%"
                    >
                      <tbody>
                      <tr>
                        <td
                          :style="{
                                'border-top': '4px solid ' + barColor,
                                'border-bottom': '1px solid #ced3d6',
                                'border-collapse': 'collapse',
                                'color': '#2e3949',
                                'font-size': '15px',
                                'letter-spacing': '-0.04em',
                                'line-height': '1',
                                'padding': '24px'
                              }"
                        >
                          <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            style="
                                  border-collapse: collapse;
                                  table-layout: fixed;
                                "
                            width="100%"
                          >
                            <tbody>
                            <tr>
                              <td
                                align="center"
                                style="
                                        border-collapse: collapse;
                                        color: #2e3949;
                                        font-size: 15px;
                                        letter-spacing: -0.04em;
                                        line-height: 1;
                                      "
                              >
                                <a
                                  :href="address"
                                  style="
                                          color: #2e3949;
                                          text-decoration: none;
                                        "
                                  target="_blank"
                                >
                                  <img
                                    :src="logo"
                                    style="width: 120px"
                                  />
                                </a>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td
                          style="
                                border-collapse: collapse;
                                color: #2e3949;
                                font-size: 15px;
                                letter-spacing: -0.04em;
                                line-height: 1;
                                padding: 34px;
                                padding-left: 3.6%;
                                padding-right: 3.6%;
                              "
                        >
                          <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            style="
                                  border-collapse: collapse;
                                  table-layout: fixed;
                                  text-align: center;
                                "
                            width="100%"
                          >
                            <tbody>
                            <tr>
                              <td
                                style="
                                        border-collapse: collapse;
                                        color: #2e3949;
                                        font-size: 15px;
                                        letter-spacing: -0.04em;
                                        line-height: 1;
                                        padding: 20px;
                                        padding-left: 4.5%;
                                        padding-right: 4.5%;
                                      "
                              >
                                <div
                                  style="
                                          font-size: 27px;
                                          letter-spacing: inherit;
                                          line-height: 1.4;
                                          margin-bottom: 28px;
                                        "
                                >
                                  문서 서명 요청 안내
                                </div>
                                <div
                                  style="
                                          letter-spacing: inherit;
                                          line-height: 1.6;
                                        "
                                >
                                  문서에 대한 서명 요청입니다.<br/>아래
                                  서명 정보를 확인해주세요.<br/>하단
                                  버튼을 통해 서명을 진행하실 수
                                  있습니다..
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style="
                                        border-collapse: collapse;
                                        color: #2e3949;
                                        font-size: 15px;
                                        letter-spacing: -0.04em;
                                        line-height: 1;
                                        padding: 20px;
                                        padding-left: 4.5%;
                                        padding-right: 4.5%;
                                      "
                              >
                                <table
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  style="
                                          border-collapse: collapse;
                                          table-layout: fixed;
                                          text-align: left;
                                        "
                                  width="100%"
                                >
                                  <tbody>
                                  <tr>
                                    <td
                                      style="
                                                border-bottom: 1px solid #ced3d6;
                                                border-collapse: collapse;
                                                border-top: 1px solid #ced3d6;
                                                color: #2e3949;
                                                font-size: 15px;
                                                letter-spacing: -0.04em;
                                                line-height: 1;
                                                padding: 16px 12px 16px 12px;
                                              "
                                    >
                                      <table
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                        style="
                                                  border-collapse: collapse;
                                                  table-layout: fixed;
                                                "
                                        width="100%"
                                      >
                                        <tbody>
                                        <tr>
                                          <th
                                            align="left"
                                            style="
                                                        border-collapse: collapse;
                                                        color: #2e3949;
                                                        font-size: 15px;
                                                        letter-spacing: -0.04em;
                                                        line-height: 1.4;
                                                        min-width: 100px;
                                                        padding: 4px 6px 4px 0;
                                                      "
                                            valign="top"
                                            width="25%"
                                          >
                                            요청된 문서
                                          </th>
                                          <td
                                            align="left"
                                            style="
                                                        border-collapse: collapse;
                                                        color: #2e3949;
                                                        font-size: 15px;
                                                        letter-spacing: -0.04em;
                                                        line-height: 1.4;
                                                        padding: 4px 0;
                                                      "
                                            valign="top"
                                          >
                                            박유캔 - 표준 근로계약서
                                          </td>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style="
                                                border-bottom: 1px solid #ced3d6;
                                                border-collapse: collapse;
                                                border-top: 1px solid #ced3d6;
                                                color: #2e3949;
                                                font-size: 15px;
                                                letter-spacing: -0.04em;
                                                line-height: 1;
                                                padding: 16px 12px 16px 12px;
                                              "
                                    >
                                      <table
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                        style="
                                                  border-collapse: collapse;
                                                  table-layout: fixed;
                                                "
                                        width="100%"
                                      >
                                        <tbody>
                                        <tr>
                                          <th
                                            align="left"
                                            style="
                                                        border-collapse: collapse;
                                                        color: #2e3949;
                                                        font-size: 15px;
                                                        letter-spacing: -0.04em;
                                                        line-height: 1.4;
                                                        min-width: 100px;
                                                        padding: 4px 6px 4px 0;
                                                      "
                                            valign="top"
                                            width="25%"
                                          >
                                            서명 요청자
                                          </th>
                                          <td
                                            align="left"
                                            style="
                                                        border-collapse: collapse;
                                                        color: #2e3949;
                                                        font-size: 15px;
                                                        letter-spacing: -0.04em;
                                                        line-height: 1.4;
                                                        padding: 4px 0;
                                                      "
                                            valign="top"
                                          >
                                            <span>김유캔</span>
                                            (<span>kim@ucansign.com</span>)
                                          </td>
                                        </tr>
                                        <tr>
                                          <th
                                            align="left"
                                            style="
                                                        border-collapse: collapse;
                                                        color: #2e3949;
                                                        font-size: 15px;
                                                        letter-spacing: -0.04em;
                                                        line-height: 1.4;
                                                        min-width: 100px;
                                                        padding: 4px 6px 4px 0;
                                                      "
                                            valign="top"
                                            width="25%"
                                          >
                                            서명 참여자
                                          </th>
                                          <td
                                            align="left"
                                            style="
                                                        border-collapse: collapse;
                                                        color: #2e3949;
                                                        font-size: 15px;
                                                        letter-spacing: -0.04em;
                                                        line-height: 1.4;
                                                        padding: 4px 0;
                                                      "
                                            valign="top"
                                          >
                                            <span>박유캔</span>
                                            (<span>park@ucansign.com</span>)
                                          </td>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style="
                                                border-bottom: 1px solid #ced3d6;
                                                border-collapse: collapse;
                                                border-top: 1px solid #ced3d6;
                                                color: #2e3949;
                                                font-size: 15px;
                                                letter-spacing: -0.04em;
                                                line-height: 1;
                                                padding: 16px 12px 16px 12px;
                                              "
                                    >
                                      <table
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                        style="
                                                  border-collapse: collapse;
                                                  table-layout: fixed;
                                                "
                                        width="100%"
                                      >
                                        <tbody>
                                        <tr>
                                          <th
                                            align="left"
                                            style="
                                                        border-collapse: collapse;
                                                        color: #2e3949;
                                                        font-size: 15px;
                                                        letter-spacing: -0.04em;
                                                        line-height: 1.4;
                                                        min-width: 100px;
                                                        padding: 4px 6px 4px 0;
                                                      "
                                            valign="top"
                                            width="25%"
                                          >
                                            서명 유효기간
                                          </th>
                                          <td
                                            align="left"
                                            style="
                                                        border-collapse: collapse;
                                                        color: #2e3949;
                                                        font-size: 15px;
                                                        letter-spacing: -0.04em;
                                                        line-height: 1.4;
                                                        padding: 4px 0;
                                                      "
                                            valign="top"
                                          >
                                            2021년 07월 27일 12시 30분
                                            30초
                                          </td>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style="
                                        border-collapse: collapse;
                                        color: #2e3949;
                                        font-size: 15px;
                                        letter-spacing: -0.04em;
                                        line-height: 1;
                                        padding: 20px;
                                        padding-left: 4.5%;
                                        padding-right: 4.5%;
                                      "
                              >
                                <a
                                  style="
                                          color: #2e3949;
                                          text-decoration: none;
                                        "
                                >
                                        <span
                                          :style="{
                                            'background-color': buttonColor,
                                            'color': textColor,
                                            'display': 'inline-block',
                                            'font-size': '16px',
                                            'font-weight': '600m',
                                            'letter-spacing': '-0.04em',
                                            'line-height': '1',
                                            'padding': '16px 24px'
                                          }"
                                        >
                                          문서 열람 및 서명하기
                                        </span>
                                </a>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                </tbody>
              </table>
              <table
                border="0"
                cellpadding="0"
                cellspacing="0"
                style="
                  border-collapse: collapse;
                  table-layout: fixed;
                  width: 650px;
                  margin: auto;
                "
                th:fragment="footer"
              >
                <tr class="LayoutTable__sub-row">
                  <td
                    style="
                      border-collapse: collapse;
                      color: #2e3949;
                      font-size: 15px;
                      letter-spacing: -0.04em;
                      line-height: 1;
                    "
                  >
                    <table
                      bgcolor="#ffffff"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      style="
                        border-collapse: collapse;
                        outline: 3px solid #f5f8fa;
                        table-layout: fixed;
                      "
                      width="100%"
                    >
                      <tbody>
                      <tr>
                        <td
                          style="
                              border-collapse: collapse;
                              color: #2e3949;
                              font-size: 15px;
                              letter-spacing: -0.04em;
                              line-height: 1;
                              padding-bottom: 24px;
                              padding-left: 7.2%;
                              padding-right: 7.2%;
                              padding-top: 48px;
                            "
                        >
                          <div
                            style="
                                color: #878d91;
                                letter-spacing: inherit;
                                line-height: inherit;
                              "
                          >
                            <div
                              style="
                                  letter-spacing: inherit;
                                  line-height: inherit;
                                  margin-bottom: 24px;
                                "
                            >
                              <div
                                style="
                                    font-size: 14px;
                                    font-weight: 700;
                                    letter-spacing: inherit;
                                    line-height: inherit;
                                    margin-bottom: 6px;
                                  "
                              >
                                서명 과정 중 보안 준수 의무
                              </div>
                              <div
                                style="
                                    font-size: 14px;
                                    letter-spacing: inherit;
                                    line-height: 1.4;
                                  "
                              >
                                유캔싸인에서 전달되는 메일과 링크가 제3자에게
                                노출되지 않도록 주의해주세요. 메일과 링크가
                                타인에게 공유, 혹은 노출될 경우에는 승인되지
                                않은 자가 문서를 열람하거나 서명할 수도
                                있습니다. 이와 관련하여 발생하는 문제에 대해서
                                유캔싸인은 어떠한 책임도 지지 않습니다.
                              </div>
                            </div>
                            <div
                              style="
                                  letter-spacing: inherit;
                                  line-height: inherit;
                                  margin-bottom: 24px;
                                "
                            >
                              <div
                                style="
                                    font-size: 14px;
                                    font-weight: 700;
                                    letter-spacing: inherit;
                                    line-height: inherit;
                                    margin-bottom: 6px;
                                  "
                              >
                                이메일 계정 유지 의무
                              </div>
                              <div
                                style="
                                    font-size: 14px;
                                    letter-spacing: inherit;
                                    line-height: 1.4;
                                  "
                              >
                                유캔싸인은 계약과 관련된 중요한 내용을 메일로
                                전송합니다. 따라서 계약 당사자는 메일을
                                정상적으로 수신할 수 있도록 이메일 계정을
                                유지, 관리할 의무가 있습니다. 계정 삭제, 메일
                                삭제, 수신 거부 등 사용자 귀책사유로 인해
                                메일이 전송되지 않아 문제가 발생하는 경우
                                유캔싸인은 어떠한 책임도 지지 않습니다.
                              </div>
                            </div>
                            <div
                              style="
                                  letter-spacing: inherit;
                                  line-height: inherit;
                                  margin-bottom: 24px;
                                "
                            >
                              <div
                                style="
                                    font-size: 14px;
                                    font-weight: 700;
                                    letter-spacing: inherit;
                                    line-height: inherit;
                                    margin-bottom: 6px;
                                  "
                              >
                                전자계약의 효력 및 문서의 보안
                              </div>
                              <div
                                style="
                                    font-size: 14px;
                                    letter-spacing: inherit;
                                    line-height: 1.4;
                                  "
                              >
                                유캔싸인에서 이루어지는 모든 전자계약은
                                전자서명법, 전자문서 및 전자거래 기본법 등에
                                의해서 종이 계약과 동등한 효력을 가집니다.
                                또한, 서명 진행 과정 중 발생하는 정보 및
                                데이터는 모두 암호화를 거쳐 유캔싸인의 감사
                                추적 인증서에 기록됩니다.
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td
                    align="center"
                    style="
                      border-collapse: collapse;
                      color: #878d91;
                      font-size: 14px;
                      letter-spacing: -0.04em;
                      line-height: 1.4;
                      padding: 24px;
                    "
                  >
                    본 메일은 발신 전용으로 회신이 되지 않습니다.<br/>
                    문의는
                    <a
                      href="https://ucansign.oopy.io/"
                      style="color: #878d91; text-decoration: underline"
                      target="_blank"
                    >고객센터</a
                    >를 이용해주세요.<br/>
                    <br/>
                    Copyright © 2022 UDID Inc. All rights reserved.<br/>
                    <a
                      href="https://ucansign.com/inc/terms.html"
                      style="color: #878d91; text-decoration: none"
                      target="_blank"
                    >이용약관</a
                    >&nbsp;|&nbsp;
                    <a
                      href="https://ucansign.com/inc/privacy.html"
                      style="
                        color: #878d91;
                        font-weight: 700;
                        text-decoration: none;
                      "
                      target="_blank"
                    >개인정보처리방침</a
                    >&nbsp;|&nbsp;
                    <a
                      href="https://ucansign.oopy.io/"
                      style="color: #878d91; text-decoration: none"
                      target="_blank"
                    >고객센터</a
                    >
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="btn-row">
        <div class="btn-table">
          <a
            class="btn btn-gray-line"
            style="height: 40px"
            @click="handleCancel"
          ><span style="vertical-align: inherit"
          ><span style="vertical-align: inherit">취소</span></span
          ></a
          >
          <a class="btn btn-blue" style="height: 40px" @click="handleOK"
          ><span style="vertical-align: inherit"
          ><span style="vertical-align: inherit">저장하기</span></span
          ></a
          >
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { defineComponent, onUpdated, reactive, toRefs, watch } from 'vue'
import { setEmailInfo, uploadImage } from 'api/settings'
import { useStore } from 'vuex'
import { Sketch } from '@ckpack/vue-color'
import { file2Base64 } from 'utils/helper'
import { funcIsEnable } from 'utils/permission_unused'
import { LOGO_BYTE_URL } from 'config/config'

export default defineComponent({
  components: {
    Sketch
  },
  setup (props, context) {
    const store = useStore()
    const state = reactive({
      logoId: store.state.settings.email.logoId,
      logo: store.state.settings.email.logo,
      address: store.state.settings.email.address,
      desc: store.state.settings.email.desc,
      barColor: store.state.settings.email.barColor,
      buttonColor: store.state.settings.email.buttonColor,
      textColor: store.state.settings.email.textColor,

      isUploadingImage: false,
      isSaving: false
    })

    watch(() => state.barColor, (newVal) => {
      if ((typeof newVal) !== 'string') {
        state.barColor = newVal.hex
      }
    })
    watch(() => state.buttonColor, (newVal) => {
      if ((typeof newVal) !== 'string') {
        state.buttonColor = newVal.hex
      }
    })
    watch(() => state.textColor, (newVal) => {
      if ((typeof newVal) !== 'string') {
        state.textColor = newVal.hex
      }
    })

    onUpdated(() => {
      state.logoId = store.state.settings.email.logoId
      state.logo = store.state.settings.email.logo
      state.address = store.state.settings.email.address
      state.desc = store.state.settings.email.desc
      state.barColor = store.state.settings.email.barColor
      state.buttonColor = store.state.settings.email.buttonColor
      state.textColor = store.state.settings.email.textColor
    })

    const handleChangeLogo = (file) => {
      state.isUploadingImage = true
      file2Base64(file)
        .then((res) => {
          const base64 = res.split(',')[1]
          const nameArray = file.name.split('.')
          uploadImage(base64, '.' + nameArray[nameArray.length - 1])
            .then((res) => {
              state.logoId = res.result.fileId
              state.logo = LOGO_BYTE_URL[process.env.NODE_ENV] + res.result.fileId
            })
            .finally(() => {
              state.isUploadingImage = false
            })
        })
        .catch(() => {
          state.isUploadingImage = false
        })
      return false
    }

    const handleCancel = () => {
      context.emit('close')
    }

    const handleOK = () => {
      setEmailInfo(
        state.logoId,
        state.address,
        state.desc,
        state.barColor,
        state.buttonColor,
        state.textColor
      ).then((res) => {
        handleCancel()
      })
    }

    return {
      ...toRefs(state),
      handleChangeLogo,
      handleCancel,
      handleOK,
      funcIsEnable
    }
  }
})
</script>

<style lang="less">
.ant-popover {
  z-index: 9999;
}
</style>
