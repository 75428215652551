<template>
  <UcsModal
    v-model:visible="visible"
    :zIndex="990"
  >
    <template #title>
      <span style="margin-right:0.5rem">클라이언트 정보</span>
    </template>
    <template #content>
      <a-spin :spinning="isLoading">
        <div v-if="!isLoading" style="padding: 2rem;">
          <h3 class="tit">
            클라이언트 이름
          </h3>
          <a-input v-model:value="record.clientName" class="ele" readonly/>
          <a-divider/>
          <h3 class="tit">
            클라이언트 ID
          </h3>
          <a-input :value="record.clientId" class="ele" readonly/>
          <a-divider/>
          <h3 class="tit">
            클라이언트 시크릿
          </h3>
          <div class="flex-s">
            <a-input :value="'******'" class="ele" disabled readolny/>
            <a-popover :visible="isCopied" trigger="click">
              <template #content>
                <p>클립보드에 저장되었습니다.</p>
              </template>
              <a-button @click="copyClientSecret">
                <copy-outlined/>
                복사
              </a-button>
            </a-popover>
            <a-button @click="confirmReissueSecret = true">
              <sync-outlined/>
              재발급
            </a-button>
          </div>
          <a-divider/>
          <!-- <h3 class="tit">
            클라이언트 로고
          </h3>
          <img
            :width="100"
            :height="100"
            src="@\assets\images\payapp_service.png"
          />
          <a-divider /> -->
          <h3 class="tit">
            리다이렉트 URI
          </h3>
          <div v-for="item in record.redirectUris" :key="item.id" class="list">
            <a-input v-model:value="item.redirectUri" class="ele" readonly/>
          </div>
          <div v-if="record.redirectUris.length < 1">
            저장된 리다이렉트 URI가 없습니다. 리다이렉트 URI를 추가해 주시기 바랍니다.
          </div>
        </div>
      </a-spin>
    </template>
  </UcsModal>
  <UcsConfirm v-if="confirmReissueSecret" :visible="confirmReissueSecret" cancelText="취소" confirmText="확인" @onClickCancel="confirmReissueSecret = false" @onClickConfirm="reissueClientSecret">
    <template #cusTitle>
      <h3>클라이언트 시크릿 갱신</h3>
    </template>
    <template #cusContent>
      클라이언트 시크릿을 갱신하게 될 경우, 기존의 클라이언트 시크릿은 사용할 수 없습니다.<br/>
      그래도 진행하시겠습니까?
    </template>
  </UcsConfirm>
</template>

<script>
import { onUnmounted, reactive, toRefs } from 'vue'
import UcsModal from '@/components/common/UcsModal.vue'
import { CopyOutlined, SyncOutlined } from '@ant-design/icons-vue'
import UcsConfirm from '@/components/modal/UcsConfirm'
import { getClientInfo, reissueClientSecret as reissue } from 'api/developer'
import { Modal } from 'ant-design-vue'

export default {
  components: {
    UcsModal,
    SyncOutlined,
    CopyOutlined,
    UcsConfirm
  },
  setup () {
    const state = reactive({
      record: null,
      isLoading: true,
      isCopied: false,
      visible: false,
      confirmReissueSecret: false
    })

    const cancel = () => {
      state.visible = false
    }

    const show = clientId => {
      state.visible = true
      fetchData(clientId)
    }

    const fetchData = (clientId) => {
      state.isLoading = true
      getClientInfo(clientId)
        .then(res => {
          state.record = res.result
          state.isLoading = false
        }).catch(() => {
          Modal.error({
            content: '조회 에러',
            onOk () {
              cancel()
            }
          })
        })
    }

    const copyClientSecret = () => {
      const $textarea = document.createElement('textarea')
      document.body.appendChild($textarea)
      $textarea.value = state.record.clientSecret
      $textarea.select()
      document.execCommand('copy')
      document.body.removeChild($textarea)
      showMention()
    }

    const reissueClientSecret = () => {
      reissue(state.record.clientId)
        .then(() => {
          fetchData(state.record.clientId)
          Modal.success({ content: '재발급이 완료되었습니다' })
        }).catch(() => {
          Modal.error({ content: '재발급 실패' })
        }).finally(() => {
          state.confirmReissueSecret = false
        })
    }

    const showMention = () => {
      state.isCopied = true
      setTimeout(() => {
        state.isCopied = false
      }, 1500)
    }

    const confirm = () => {
      state.visible = false
    }

    onUnmounted(() => {
      state.record = null
    })

    return {
      ...toRefs(state),
      show,
      copyClientSecret,
      reissueClientSecret,
      confirm,
      cancel
    }
  }

}
</script>
<style lang="less" scoped>
h3.tit {
  text-align: left;
  font-size: 1.5rem;
  font-weight: 500;
}

.list {
  display: flex;
  justify-content: space-between;
}

.ele {
  margin-top: 2px;
}

.btn {
  height: 2.667em;
  font-size: 1.5rem;
}

.flex-s {
  display: flex;
  justify-content: space-between;
  margin-top: 2px;

  .ele {
    margin: 0
  }

  button {
    margin-left: 0.5rem
  }
}
</style>
