import request from 'utils/request'
import store from '../store/index'
import { LOGO_BYTE_URL } from 'config/config'

/**
 * @description: 获取定制信息
 * @param {} 无参数
 * @return {Promise}
 */
export const info = () => {
  return new Promise((resolve, reject) => {
    request.get('customBrandSetting', { config: { isShowMessage: false } })
      .then((res) => {
        console.log(res.result)
        const emailInfo = {
          logoId: res.result.logoImageFileId,
          logo: LOGO_BYTE_URL[process.env.NODE_ENV] + res.result.logoImageFileId,
          address: res.result.logoLink,
          desc: res.result.description,
          barColor: res.result.barColor,
          buttonColor: res.result.buttonColor,
          textColor: res.result.buttonTextColor
        }
        store.dispatch('updateEmailInfo', emailInfo)
        store.dispatch('updateSender', { name: res.result.senderName })
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * @description: 修改发送者的名字
 * @param {String} name
 * @return {Promise}
 */
export const setSenderName = (name) => {
  return request.put(`customBrandSetting/senderName?senderName=${name}`, '', { config: { isShowMessage: false } })
}

/**
 * @description: 修改邮件信息
 * @param {String} name
 * @return {Promise}
 */
export const setEmailInfo = (logoId, logoLink, desc, barColor, buttonColor, buttonTextColor) => {
  return new Promise((resolve, reject) => {
    const parma = {
      logoImageFileId: logoId,
      logoLink: logoLink,
      description: desc,
      barColor: barColor,
      buttonColor: buttonColor,
      buttonTextColor: buttonTextColor
    }
    request.put(
      'customBrandSetting/style',
      parma,
      { config: { isShowMessage: false } }
    )
      .then((res) => {
        const emailInfo = {
          logoId: res.result.logoImageFileId,
          logo: res.result.logoImageUrl,
          address: res.result.logoLink,
          desc: res.result.description,
          barColor: res.result.barColor,
          buttonColor: res.result.buttonColor,
          textColor: res.result.buttonTextColor
        }
        store.dispatch('updateEmailInfo', emailInfo)
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * @description: 上传图片
 * @param {String, String} 文件base64字符串，扩展名
 * @return {Promise}
 */
export const uploadImage = (base64, ext) => {
  return request.post('customBrandSetting/upload', {
    ext: ext,
    base64: base64
  }, { config: { isShowMessage: false } })
}
