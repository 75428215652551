<template>
  <a-modal
    v-model:visible="isShow"
    :footer="null"
    :bodyStyle="{ padding: 0 }"
    :closable="false"
    :keyboard="false"
    :maskClosable="false"
    :width="width"
  >
    <div class="settings-modal-header">
      <slot name="title"></slot>
      <span role="button" class="modal-close" @click="onClose">닫기</span>
    </div>
    <div>
      <slot name="content"></slot>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, watchEffect } from 'vue'

export default {
  props: ['width', 'visible'],
  setup (props, { emit }) {
    const state = reactive({
      isShow: props.visible
    })

    const onClose = () => {
      state.isShow = false
    }

    watchEffect(() => {
      emit('update:visible', state.isShow)
    })

    watchEffect(() => {
      state.isShow = props.visible
    })

    return {
      onClose,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
.settings-modal-header {
  border-bottom-width: 1px;
  border-bottom-color: rgb(240, 240, 240);
  border-bottom-style: solid;
}
</style>
