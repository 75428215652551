<template>
  <div class="info">
    <div class="inner">
      <div v-if="organizationId.length === 0" class="info-cont">
        <p class="info-guide">
          하나의 조직에 여러 팀이 존재하는 환경에서 사용하기 유용해요.<br/>
          각 팀별로 문서, 템플릿 등을 관리하고 구성원간 공유를 통해 원활한 협업이 가능합니다.
        </p>
        <div class="horizon-list">
          <div>
            <div class="list-tit">
              <h4>조직 관리</h4>
            </div>
            <ul class="info-list">
              <li>
                조직 이름
                <div class="input-group form-control input-group-lg">
                  <input v-model="newOrganizationName" type="text"/>
                  <a
                    :class="
                      newOrganizationName.length === 0
                        ? 'input-group-addon btn-inactive'
                        : 'input-group-addon btn-active'
                    "
                    :style="{
                      'pointer-events':
                        newOrganizationName.length === 0 ? 'none' : 'auto',
                      'text-decoration': 'none'
                    }"
                    @click="createOrganization()"
                  >
                    조직 생성하기
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-else class="info-cont">
        <div class="horizon-list">
          <div>
            <div class="list-tit">
              <h4>조직 이름</h4>
            </div>
            <ul class="info-list">
              <li v-if="changingName === false">
                <span>{{ organizationName }}</span>
                <div v-checkPermission="{action: 'EDIT_ORGANIZATION_NAME'}" class="btn-inline">
                  <a class="text-blue" @click="changeName()">변경하기</a>
                </div>
              </li>
              <li v-else>
                변경할 이름
                <div class="input-group form-control input-group-lg">
                  <input
                    v-model="newName"
                    maxlength="32"
                    type="text"
                  />
                  <span
                    :class="(newName.length === 0 || savingName) ? 'input-group-addon btn-inactive' : 'input-group-addon btn-active'"
                    :style="{ 'pointer-events' : ((newName.length === 0 || savingName) ? 'none' : 'auto') }"
                    role="button"
                    @click="saveNewName()"
                  >
                    저장하기
                  </span>
                  <a-spin :spinning="savingName"/>
                </div>
              </li>
            </ul>
          </div>
          <div>
            <div class="list-tit">
              <h4>조직 관리자</h4>
            </div>
            <ul class="info-list">
              <li>{{ managerName }} <span class="mail">{{ managerEmail }}</span></li>
            </ul>
          </div>
          <div>
            <div class="list-tit">
              <h4>삭제된 조직 문서</h4>
            </div>
            <ul class="info-list">
              <li>삭제된 문서 목록을 확인하거나, 문서를 복구할 수 있습니다.</li>
              <li>
                <button
                  class="btn btn-blue"
                  type="button"
                  @click="showDeletedDocuments()"
                >
                  삭제된 문서 목록 보기
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal v-model:visible="showCreateOrgConfirm" width="520px">
    <template #title>
      <div class="create-org-confirm-title center-box">
        <h3>조직 생성 안내</h3>
        <p>
          조직 생성 시 주의사항을 확인해 주세요.
        </p>
      </div>
    </template>
    <template #content>
      <div class="create-org-confirm-body">
        <div class="create-org-confirm-tip-bg center-box">
          <h3>{{ email }}</h3>
          <p>
            위 이메일이 최고 관리자의 계정 이메일이 됩니다.
          </p>
        </div>
        <ul>
          <li>조직 생성 이후 개인 계정으로 전환할 수 없습니다.</li>
          <li>조직 생성 이후 최고 관리자를 다른 아이디로 변경할 수 없습니다.</li>
          <li>기존 문서와 템플릿은 자동 이전되어 조직에서 그대로 이용할 수 있습니다.</li>
          <li>삭제한 문서는 자동 이전되지 않기 때문에 필요 시 조직 생성 이전에 복구하시기 바랍니다.</li>
        </ul>
        <a-checkbox
          v-model:checked="agreeCreateOrg"
          class="create-org-agree-checkbox"
        >위 내용을 모두 확인했습니다.
        </a-checkbox
        >
        <div class="center-box create-org-buttons-wrap">
          <a-button
            :disabled="creatingOrganization"
            class="create-org-button"
            size="large"
            @click="cancelCreate"
          >취소
          </a-button
          >
          <a-button
            :disabled="agreeCreateOrg === false"
            :loading="creatingOrganization"
            class="create-org-button"
            size="large"
            type="primary"
            @click="agreeCreate"
          >생성하기
          </a-button
          >
        </div>
      </div>
    </template>
  </modal>
  <RecoverDeletedDoc
    v-model:organizationId="organizationId"
    v-model:visible="showRecoverDeletedDoc"
  />
</template>

<script>
import Modal from './settingsModal.vue'
import RecoverDeletedDoc from './recoverDeletedDoc.vue'
import { defineComponent, inject, onMounted, reactive, toRefs, watch } from 'vue'
import { useStore } from 'vuex'
import { changeOrganizationName as changeOrganizationNameApi, createOrganization as createOrganizationApi } from 'api/organization'
import { Modal as AntModal } from 'ant-design-vue'
import { useRouter } from 'vue-router'

const formLabelCol = {
  style: {
    width: '130px',
    'font-size': '16px',
    'font-weight': '500'
  }
}

export default defineComponent({
  components: {
    Modal,
    RecoverDeletedDoc
  },
  setup (props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const globalSpin = inject('globalSpin')

    const getValue = (object, key) => {
      if (object !== null && object[key] !== null && object !== undefined && object[key] !== undefined) {
        return object[key]
      } else {
        return ''
      }
    }

    const state = reactive({
      email: store.state.user.userInfo.email,
      organizationId: getValue(
        store.state.organization.organization,
        'organizationId'
      ),
      newOrganizationName: '',
      creatingOrganization: false,
      organizationName: getValue(
        store.state.organization.organization,
        'organizationName'
      ),
      managerName: getValue(
        store.state.organization.organization,
        'ownerName'
      ),
      managerEmail: getValue(
        store.state.organization.organization,
        'ownerEmail'
      ),
      showCreateOrgConfirm: false,
      agreeCreateOrg: false,
      changingName: false,
      newName: '',
      savingName: false,
      showRecoverDeletedDoc: false
    })

    watch(
      () => store.state.organization.organization,
      (newValue) => {
        state.organizationId = getValue(
          newValue,
          'organizationId'
        )
        state.managerName = getValue(newValue, 'ownerName')
        state.managerEmail = getValue(newValue, 'ownerEmail')
        state.organizationName = getValue(
          newValue,
          'organizationName'
        )
      }
    )

    const updateInfo = () => {
      store.dispatch('userInfo')
    }

    onMounted(() => {
      updateInfo()
    })

    const createOrganization = () => {
      state.showCreateOrgConfirm = true
    }

    const cancelCreate = () => {
      state.showCreateOrgConfirm = false
      state.agreeCreateOrg = false
    }

    const agreeCreate = () => {
      state.creatingOrganization = true
      createOrganizationApi(state.newOrganizationName)
        .then((res) => {
          state.showCreateOrgConfirm = false
          state.agreeCreateOrg = false
          AntModal.success({
            content: '조직 생성이 완료되었습니다',
            onOk () {
              store.dispatch('getOrganizationInfo').then(() => {
                router.go(0)
              })
            }
          })
        })
        .catch((err) => {
          console.log(err)
          AntModal.error({ content: err.msg })
        })
        .finally(() => {
          state.creatingOrganization = false
        })
    }
    // userid 1877267512107958273
    // orgid 1881895021147529218
    // teamid 1881895021147529219
    // memberid 1881895021147529220

    const changeName = () => {
      state.changingName = true
      state.newName = state.organizationName
    }

    const saveNewName = () => {
      state.savingName = true
      if (state.newName === state.organizationName) {
        state.savingName = false
        state.changingName = false
      } else {
        changeOrganizationNameApi(state.newName)
          .then(() => {
            globalSpin.show()
            store.dispatch('getOrganizationInfo').then(() => {
              router.go(0)
            })
          })
          .catch((err) => {
            AntModal.error({ content: err.msg })
          })
      }
    }

    const showDeletedDocuments = () => {
      state.showRecoverDeletedDoc = true
    }

    return {
      ...toRefs(state),
      formLabelCol,
      updateInfo,
      createOrganization,
      cancelCreate,
      agreeCreate,
      changeName,
      saveNewName,
      showDeletedDocuments
    }
  }
})
</script>

<style lang="less" scoped>
.create-org-name {
  margin: 6px 0px;
}

.create-org-wrap {
  display: flex;
}

.create-org-input {
  width: 277px;
}

.center-box {
  display: flex;
  align-items: center;
}

.create-org-confirm-title {
  flex-direction: column;
  padding: 45px 40px 15px 40px;
}

.create-org-confirm-title h3 {
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
}

.create-org-confirm-title p {
  font-size: 13px;
  font-weight: 400;
  color: rgb(166, 166, 166);
  padding: 6px 0px;
}

.create-org-confirm-body {
  padding: 18px 27px;
}

.create-org-confirm-tip-bg {
  flex-direction: column;
  border: 1px solid rgb(217, 239, 251);
  padding: 8px 16px;
  background-color: rgb(231, 247, 255);
}

.create-org-confirm-tip-bg h3 {
  font-size: 14px;
  font-weight: 600;
  color: rgb(24, 144, 255);
}

.create-org-confirm-tip-bg p {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}

.create-org-confirm-body ul {
  padding: 8px 0px 24px 0px;
  font-size: 13px;
  font-weight: 400;
  color: #666;
}

.create-org-confirm-body li {
  list-style: disc inside;
  padding-top: 3px;
}

.create-org-agree-checkbox {
  font-size: 13px;
  font-weight: 400;
  color: #8c8c8c;
}

.create-org-buttons-wrap {
  flex-direction: row;
  justify-content: center;
  margin: 30px 0px 10px 0px;
}

.create-org-button {
  width: 152px;
  height: 38px;
  margin: 0px 3px;
}
</style>
