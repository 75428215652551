<template>
  <UcsModal
    v-model:visible="visible"
    :zIndex="990"
    @close="cancel"
  >
    <template #title>
      <span style="margin-right:0.5rem">웹훅 {{ isCreate ? '생성' : '수정' }}</span>
    </template>
    <template #content>
      <a-spin :spinning="isLoading">
        <div style="padding: 2rem">
          <a-form ref="formRef" :model="formState" :rules="rules">
            <a-form-item class="form-tit" name="name">
              <h3 class="tit">
                웹훅이름
              </h3>
              <a-input v-model:value="formState.name" class="ele" placeholder="웹훅 이름을 입력해 주세요"/>
            </a-form-item>
            <a-divider/>
            <a-form-item class="form-tit" name="url">
              <h3 class="tit">
                URL
              </h3>
              <a-input v-model:value="formState.url" class="ele" placeholder="url을 입력해 주세요(http or https 필수)"/>
            </a-form-item>
            <a-divider/>
            <h3 class="tit">
              구독 리스트
            </h3>
            <ul>
              <li>
                <a-checkable-tag v-model:checked="formState.eventSignCreating">
                  <component :is="isSubscribing(formState.eventSignCreating)"/>
                  서명 생성
                </a-checkable-tag>
                <a-checkable-tag v-model:checked="formState.eventSigningCanceled">
                  <component :is="isSubscribing(formState.eventSigningCanceled)"/>
                  서명 요청 취소
                </a-checkable-tag>
                <a-checkable-tag v-model:checked="formState.eventSigningCompleted">
                  <component :is="isSubscribing(formState.eventSigningCompleted)"/>
                  서명 완료
                </a-checkable-tag>
                <a-checkable-tag v-model:checked="formState.eventSigningCompletedAll">
                  <component :is="isSubscribing(formState.eventSigningCompletedAll)"/>
                  모든 서명 완료
                </a-checkable-tag>
              </li>
            </ul>
            <a-divider/>
          </a-form>
          <a-row v-if="isCreate" :gutter="[6, 6]" style="padding: 0 18px 20px">
            <a-col :span="12">
              <a-button :block="true" class="btn" style="color: #8c8c8c;" @click="cancel">취소</a-button>
            </a-col>
            <a-col :span="12">
              <a-button :block="true" :loading="submitting" class="btn" type="primary" @click="confirm">생성</a-button>
            </a-col>
          </a-row>
          <a-row v-else :gutter="[6, 6]" style="padding: 0 18px 20px">
            <a-col :span="8">
              <a-button :block="true" class="btn" style="color: #8c8c8c;" @click="cancel">취소</a-button>
            </a-col>
            <a-col :span="8">
              <a-button :block="true" :loading="removing" class="btn" type="danger" @click="confirmRemoveWebhook = true">삭제</a-button>
            </a-col>
            <a-col :span="8">
              <a-button :block="true" :loading="submitting" class="btn" type="primary" @click="confirm">수정</a-button>
            </a-col>
          </a-row>
        </div>
      </a-spin>
    </template>
  </UcsModal>
  <UcsConfirm v-if="confirmRemoveWebhook" :visible="confirmRemoveWebhook" cancelText="취소" confirmText="확인" @onClickCancel="confirmRemoveWebhook = false" @onClickConfirm="removeClient">
    <template #cusTitle>
      <h3>웹훅 삭제</h3>
    </template>
    <template #cusContent>
      웹훅을 삭제할 경우 복구할 수 없습니다.<br/>
      그래도 진행하시겠습니까?
    </template>
  </UcsConfirm>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import UcsModal from '@/components/common/UcsModal.vue'
import UcsConfirm from '@/components/modal/UcsConfirm'
import { getWebhookInfo, removeWebhook as remove, submitWebhook } from 'api/developer'
import { Modal } from 'ant-design-vue'

export default {
  components: {
    DeleteOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    UcsModal,
    UcsConfirm
  },
  emits: ['reload'],
  setup (props, { emit }) {
    const formRef = ref(null)
    const state = reactive({
      webhookId: null,
      isLoading: true,
      isCreate: false,
      visible: false,
      confirmRemoveWebhook: false,
      submitting: false,
      removing: false,
      rules: {
        name: [{
          required: true,
          min: 1,
          message: '이름을 입력해 주세요',
          trigger: 'change'
        }],
        url: [
          {
            required: true,
            message: 'url을 입력해 주세요',
            trigger: 'change'
          },
          {
            pattern: /^(https?:\/\/)?((([a-zA-Z\d-]+)\.)+[a-zA-Z]{2,}|(\d{1,3}\.){3}\d{1,3})?(\/\S*)?$/g,
            message: '올바른 url을 입력해 주세요. 특정 포트번호는 허용되지 않습니다',
            trigger: 'change'
          }
        ]
      }
    })

    const formState = reactive({
      name: '',
      url: '',
      eventSignCreating: false,
      eventSigningCanceled: false,
      eventSigningCompleted: false,
      eventSigningCompletedAll: false
    })

    const cancel = () => {
      state.visible = false
      formRef.value.clearValidate()
    }

    const show = clientId => {
      state.visible = true
      state.isLoading = true
      state.clientId = clientId
      if (state.clientId === null) {
        formState.name = ''
        formState.url = ''
        formState.eventSignCreating = false
        formState.eventSigningCanceled = false
        formState.eventSigningCompleted = false
        formState.eventSigningCompletedAll = false
        state.isLoading = false
        state.isCreate = true
      } else {
        state.isCreate = false
        getWebhookInfo(state.clientId)
          .then(res => {
            formState.name = res.result.name
            formState.url = res.result.url
            formState.eventSignCreating = res.result.eventSignCreating
            formState.eventSigningCanceled = res.result.eventSigningCanceled
            formState.eventSigningCompleted = res.result.eventSigningCompleted
            formState.eventSigningCompletedAll = res.result.eventSigningCompletedAll
          })
          .catch(() => {
            Modal.error({ content: '웹훅 조회 실패' })
          })
          .finally(() => {
            state.isLoading = false
          })
      }
    }

    const confirm = async () => {
      if (formState.url.includes('127.0.0.1') || formState.url.includes('localhost')) {
        Modal.error({
          content: '웹훅 url에는 로컬 주소 등록이 불가합니다'
        })
        return false
      }

      state.submitting = true
      try {
        await formRef.value.validateFields()
        submitWebhook(state.clientId, formState)
          .then(() => {
            state.visible = false
            emit('reload')
          })
          .catch(() => {
            Modal.error({ content: '웹훅 편집 실패' })
          })
          .finally(() => {
            state.submitting = false
          })
      } catch (error) {
        state.submitting = false
      }
    }

    const removeClient = () => {
      state.removing = true
      remove(state.clientId)
        .then(() => {
          state.visible = false
          state.confirmRemoveWebhook = false
          emit('reload')
        })
        .catch(() => {
          Modal.error({ content: '웹훅 삭제 실패' })
        })
        .finally(() => {
          state.removing = false
        })
    }

    const isSubscribing = record => {
      return record ? CheckCircleOutlined : CloseCircleOutlined
    }

    return {
      ...toRefs(state),
      formRef,
      formState,
      show,
      confirm,
      removeClient,
      isSubscribing,
      cancel
    }
  }

}
</script>
<style lang="less" scoped>
h3.tit {
  text-align: left;
  font-size: 1.5rem;
  font-weight: 500;
}

.list {
  display: flex;
  justify-content: space-between;
}

.ele {
  margin-top: 2px;
}

.info {
  font-weight: 500;
  color: #aaa;
  margin-left: 10px;
}

.btn {
  height: 2.667em;
  font-size: 1.5rem;
}

.ant-tag-checkable {
  color: #ff4d4f;
  background: #fff2f0;
  border-color: #ffccc7;
}

.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #ff4d4f;
}

.ant-tag-checkable-checked {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}

.ant-tag {
  margin-top: 0.6rem
}
</style>
