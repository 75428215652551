<template>
  <div class="setting">
    <div class="tab-line">
      <div class="inner">
        <ul>
          <li :class="tabClassesName(1)">
            <a @click="onTabChange(1)">내 정보</a>
          </li>
          <li :class="tabClassesName(2)">
            <a class="new" @click="onTabChange(2)">조직 정보</a>
          </li>
          <li v-checkPermission="{action: 'CUSTOM_BRAND_SETTING'}" :class="tabClassesName(3)">
            <a @click="onTabChange(3)">맞춤 브랜딩</a>
          </li>
          <!--
          <li :class="tabClassesName(4)">
            <a @click="onTabChange(4)">외부 서비스 연동</a>
          </li>
          -->
          <li v-if="isMobile === false" v-checkPermission="{action: 'CUSTOM_BRAND_SETTING'}" :class="tabClassesName(5)">
            <a @click="onTabChange(5)">개발자</a>
          </li>
        </ul>
      </div>
    </div>
    <Mine v-if="currentIndex === 1"></Mine>
    <Organization v-if="currentIndex === 2"></Organization>
    <Customize v-if="currentIndex === 3"></Customize>
    <!-- <ExternalLink v-if="currentIndex === 4"></ExternalLink> -->
    <Developer v-if="currentIndex === 5"></Developer>
  </div>
</template>

<script>
import { defineComponent, nextTick, reactive, toRefs, watch } from 'vue'
import Mine from './modules/mine.vue'
import Customize from './modules/customize.vue'
import Organization from './modules/organization.vue'
import Developer from './modules/developer.vue'
// import ExternalLink from './modules/externalLink.vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useMediaQuery } from '@vueuse/core'

export default defineComponent({
  components: {
    Mine,
    Customize,
    Organization,
    Developer
    // ExternalLink
  },
  setup (props, context) {
    const store = useStore()
    const isMobile = useMediaQuery('(max-width: 768px)')
    const isDeveloper = store.state.user.userInfo.isDeveloper
    const route = useRoute()

    const state = reactive({
      currentIndex: route.hash ? parseInt(route.hash.replace('#', '')) : 1
    })

    const router = useRouter()
    const onTabChange = (index) => {
      router.push({
        name: 'Settings',
        hash: `#${index}`
      })
    }

    const tabClassesName = (index) => {
      return state.currentIndex === index ? 'active' : ''
    }

    watch(() => route.hash, (now, _pre) => {
      const index = parseInt(now.replace('#', ''))
      if (index === 4) {
        state.currentIndex = 1
      } else if (index > 0 && index <= 5) {
        state.currentIndex = index
      } else {
        state.currentIndex = 1
      }
      if (state.currentIndex === 1) {
        nextTick(() => {
          store.dispatch('userInfo')
        })
      }

      if (store.state.organization.organization) {
        const perm = store.state.organization.permissionList.find(perm => perm.permissionName === 'CUSTOM_BRAND_SETTING')
        if (!perm && (state.currentIndex === 3 || state.currentIndex === 5)) {
          state.currentIndex = 1
        }
      }
    })

    return {
      ...toRefs(state),
      onTabChange,
      tabClassesName,
      isMobile,
      isDeveloper
    }
  }
})
</script>
