import request from 'utils/request'

export const requestDeveloper = () => {
  return request.post('/developer')
}

export const getDeveloperInfo = () => {
  return request.get('/developer')
}

export const reissueApiKey = () => {
  return request.put('/developer/key')
}

export const getClientInfo = (clientId) => {
  return request.get(`/developer/client/${clientId}`)
}

export const submitClient = (clientId, params) => {
  if (clientId !== null || clientId === undefined) {
    return request.put(`/developer/client/${clientId}`, params)
  } else {
    return request.post('/developer/client', params)
  }
}

export const reissueClientSecret = (clientId) => {
  return request.put(`/developer/client/${clientId}/secret`)
}

export const removeClient = (clientId) => {
  return request.delete(`/developer/client/${clientId}`)
}

export const getWebhookInfo = (webhookId) => {
  return request.get(`/developer/webhook/${webhookId}`)
}

export const submitWebhook = (webhookId, params) => {
  if (webhookId !== null || webhookId === undefined) {
    return request.put(`/developer/webhook/${webhookId}`, params)
  } else {
    return request.post('/developer/webhook', params)
  }
}

export const removeWebhook = (webhookId) => {
  return request.delete(`/developer/webhook/${webhookId}`)
}

export const getWebhookLog = (webhookId, params) => {
  return request.get(`/developer/webhook/${webhookId}/logs`, { params: params })
}
